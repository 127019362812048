import TripReport from '@this/domain/trip_report/trip_report/index';
import type TripList from '@this/domain/trip/trip_list';
import type { TripReportArgs } from '@this/domain/trip_report/trip_report/trip_report';

class TripReportList {
  items: TripReport[];

  static withTripList(rawItems: TripReportArgs[], tripList: TripList) {
    const items = rawItems.map(item => {
      const trip = tripList.list.find(t => t.id === item.trip_id);
      return { ...item, trip };
    });
    return new this(items);
  }

  constructor(rawItems: TripReportArgs[] = []) {
    this.items = rawItems.map(raw => new TripReport(raw));
  }

  selectedReports() {
    return this.items.filter(report => report.selected);
  }

  selectedExpenses() {
    return this.selectedReports().flatMap(report => report.selectedExpenses());
  }

  toCreateExpenseApplicationParams() {
    return { trip_reports: this.selectedReports().map(report => report.toCreateExpenseApplicationParams()) };
  }

  totalPrice() {
    return this.items.reduce((sum, e) => sum + e.totalPrice(), 0);
  }
}

export default TripReportList;
