import { Fetcher } from '@this/src/util';
import React from 'react';

import Item from '@this/components/organization/trips/exic_seisan_table/item';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import DateRangePicker from '@this/components/arrangement/todo_list/date_range_picker';
import { Box } from '@material-ui/core';
import type { Moment } from 'moment';
import moment from 'moment';
import { Button } from '@this/shared/ui/inputs/button';
import { reportError } from '@this/lib/bugsnag';
import { ExicSeisanTripCsvDownloadButton } from './exic_seisan_trip_csv_download_button';

interface Props {
  serviceId: number;
}

interface State {
  exicSeisanTrips: ExicSeisanTripInterface[];
  boardingDateFrom: Moment | undefined;
  boardingDateTo: Moment | undefined;
  filterType: 'all' | 'only_non_order' | 'only_actual';
  loading: boolean;
  totalCount: number;
}

export interface ExicSeisanTripInterface {
  id: number;
  trip_category: string;
  trip_route: string[];
  total_price: string;
  approval_channel_id: number;
  check_trip_rule: { check: boolean; error: string };
  traveler: { name: string };
}

interface ExicSeisanTripsResponse {
  exic_seisan_trips: ExicSeisanTripInterface[];
}

class ExicSeisanTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.getDefaultBoardingDateFrom = this.getDefaultBoardingDateFrom.bind(this);
    this.getDefaultBoardingDateTo = this.getDefaultBoardingDateTo.bind(this);
    this.state = {
      exicSeisanTrips: [],
      boardingDateFrom: this.getDefaultBoardingDateFrom(),
      boardingDateTo: this.getDefaultBoardingDateTo(),
      filterType: 'all',
      loading: false,
      totalCount: 0
    };
  }

  getDefaultBoardingDateFrom() {
    const str = utils.getParam('boarding_date_from');
    return str ? moment(str) : moment().subtract(1, 'months');
  }

  getDefaultBoardingDateTo() {
    const str = utils.getParam('boarding_date_to');
    return str ? moment(str) : moment();
  }

  setBoardingDateFrom = (date: Moment | undefined) => {
    this.setState({ boardingDateFrom: date });
  };

  setBoardingDateTo = (date: Moment | undefined) => {
    this.setState({ boardingDateTo: date });
  };

  componentDidMount() {
    this.fethcExicSeisanTrips();
  }

  fethcExicSeisanTrips() {
    const { boardingDateFrom, boardingDateTo, filterType } = this.state;
    const params = {
      boarding_date_from: boardingDateFrom?.format('YYYY-MM-DD'),
      boarding_date_to: boardingDateTo?.format('YYYY-MM-DD'),
      filter_type: filterType
    };

    this.setState({ loading: true });
    this.setState({ exicSeisanTrips: [] });

    Fetcher.get<ExicSeisanTripsResponse>('/organization/exic_seisan_trips', params)
      .then(result => {
        this.setState({
          exicSeisanTrips: result.exic_seisan_trips,
          totalCount: result.exic_seisan_trips.length
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleSearchClick = () => {
    this.fethcExicSeisanTrips();
  };

  handleFilterTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ filterType: event.target.value as State['filterType'] });
  };

  render() {
    try {
      const { serviceId } = this.props;
      const { loading, exicSeisanTrips, boardingDateFrom, boardingDateTo, filterType, totalCount } = this.state;
      const filterParams = {
        boarding_date_from: boardingDateFrom?.format('YYYY-MM-DD'),
        boarding_date_to: boardingDateTo?.format('YYYY-MM-DD'),
        filter_type: filterType
      };

      return (
        <>
          <SearchBlock>
            <SearchLabel>乗車日</SearchLabel>
            <DateRangePicker
              from={boardingDateFrom}
              to={boardingDateTo}
              onFromChange={this.setBoardingDateFrom}
              onToChange={this.setBoardingDateTo}
            />
            <SearchLabel>絞り込み</SearchLabel>
            <Select value={filterType} onChange={this.handleFilterTypeChange}>
              <option value="all">すべて</option>
              <option value="only_non_order">申請のみ</option>
              <option value="only_actual">実績データのみ</option>
            </Select>
            <Button onClick={() => this.fethcExicSeisanTrips()}>検索</Button>
          </SearchBlock>
          <SpaceBetween>
            <TripsSummaryArea>
              <TripsSummary>検索結果: {totalCount} 件</TripsSummary>
            </TripsSummaryArea>
            <CsvDownloadArea>
              <ExicSeisanTripCsvDownloadButton serviceId={serviceId} params={filterParams} />
            </CsvDownloadArea>
          </SpaceBetween>
          <TableWrapper>
            <TripsTable>
              <div className="organization-trips-table__thread">
                <Tr>
                  <TrContent>
                    <Th>
                      <span>旅程番号</span>
                    </Th>
                    <Th>{utils.isTabikobo(serviceId) && <span>出張種別</span>}</Th>
                    <Th>
                      <span>出張者(代表者)</span>
                    </Th>
                    <Th>
                      <span>旅程</span>
                    </Th>
                    <Th>
                      <span>料金(合計)</span>
                    </Th>
                    <Th>
                      <span>旅費規程チェッカー</span>
                    </Th>
                  </TrContent>
                </Tr>
              </div>
              <Tbody>
                {loading ? (
                  <Tr className="loading">
                    <Loading />
                  </Tr>
                ) : (
                  exicSeisanTrips.map(trip => <Item key={trip.id} serviceId={serviceId} trip={trip} />)
                )}
              </Tbody>
            </TripsTable>
          </TableWrapper>
        </>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const TripsTable = styled.div`
  width: 100%;
`;

const TableWrapper = styled.section`
  padding: 0 10px;
`;

const Tr = styled.div`
  &.loading .vertical-centered-box {
    height: 58vh;
  }
`;

const TrContent = styled.div`
  display: flex;
  align-items: center;
`;

const Th = styled.div<{ tripReport?: boolean }>`
  font-size: 12px;
  font-weight: bold;

  span {
    display: block;
    border-bottom: 2px solid #333;
    padding-bottom: 4px;
  }

  &:not(:last-child) span {
    margin-right: 12px;
  }

  &:nth-child(1) {
    width: 8%;
  }

  &:nth-child(2) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '8%' : '0%')};
  }

  &:nth-child(3) {
    width: 15%;
  }

  &:nth-child(4) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '29%' : '37%')};
  }

  &:nth-child(5) {
    width: 15%;
  }

  &:nth-child(6) {
    width: 25%;
  }
`;

export const SearchBlock = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin: 0 10px 10px 10px;
  padding: 10px;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 3px;
`;

export const SearchLabel = styled.div`
  font-weight: bold;
  margin-left: 10px;
`;

const Tbody = styled.div``;

const TripsSummaryArea = styled.div`
  display: flex;
`;

const TripsSummary = styled.p`
  font-weight: bold;
  margin-right: 10px;
`;

const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

const CsvDownloadArea = styled.div``;

const Select = styled.select`
  height: 25px;
  margin-right: 15px;
  margin-bottom: 2px;
`;

export default ExicSeisanTable;
