import moment from 'moment';
import { camelToSnakeObject } from '@this/src/util';
import type ElementBaseInterface from '@this/domain/element_base_interface';

export type ShippingElementJson = {
  id?: number;
  category?: number;
  number?: string;
  remark?: string;
  shippingCategoryOptions?: { [k in string]: string };
  shippedAt: string;
  arrivedAt: string;
};

class ShippingElement implements ElementBaseInterface {
  id?: number;

  type: string;

  category: number | string;

  shippedAt: moment.Moment;

  arrivedAt: moment.Moment;

  number: string;

  remark: string;

  shippingCategoryOptions: { [k in string]: string };

  constructor(args: ShippingElementJson = {} as ShippingElementJson) {
    this.id = args.id;
    this.type = 'shipping';
    this.shippingCategoryOptions = camelToSnakeObject(args.shippingCategoryOptions || {});
    this.shippedAt = moment(args.shippedAt);
    this.arrivedAt = args.arrivedAt ? moment(args.arrivedAt) : moment(this.shippedAt).add(1, 'day');
    this.category = args.category ?? Object.keys(this.shippingCategoryOptions)[0];
    this.number = args.number || '';
    this.remark = args.remark || '';
  }

  handleShippedAtChange(value: moment.Moment) {
    this.shippedAt = value;
    return app.render();
  }

  handleArrivedAtChange(value: moment.Moment) {
    this.arrivedAt = value;
    return app.render();
  }

  handleChangeCategory(value: string) {
    this.category = value;
    return app.render();
  }

  handleChangeNumber(value: string) {
    this.number = value;
    return app.render();
  }

  handleChangeRemark(value: string) {
    this.remark = value;
    return app.render();
  }

  startDate() {
    return this.shippedAt;
  }

  endDate() {
    return this.arrivedAt;
  }

  description() {
    return `\
<配送料>
■種別：${this.categoryName()}
■発送日時：${this.shippedAt.format('MM/DD HH:mm')}
■到着日時：${this.arrivedAt.format('MM/DD HH:mm')}
■特定記録番号：${this.number}
■備考
${this.remark}\
`;
  }

  title() {
    return '配送料';
  }

  summary() {
    return `[配送料]${this.categoryName()}`;
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  categoryName() {
    return this.shippingCategoryOptions[this.category] || '';
  }

  validationErrors() {
    const errors = [];
    if (!this.remark) {
      errors.push('配送料の備考を入力してください。');
    }
    if (this.arrivedAt.isBefore(this.shippedAt)) {
      errors.push('配送日時以降を指定してください。');
    }
    return errors;
  }

  updateParams() {
    return {
      type: this.type,
      category: this.category,
      shipped_at: this.shippedAt.format('YYYY-MM-DD HH:mm:ss'),
      arrived_at: this.arrivedAt.format('YYYY-MM-DD HH:mm:ss'),
      number: this.number,
      remark: this.remark
    };
  }
}

export default ShippingElement;
