import React from 'react';

import { styled } from '@this/constants/themes';
import { getColor, getSpacing } from '@this/shared/ui/theme';

import { ArrangementFlow } from '@this/components/arrangement/virtual_counter/virtual_counter_aside/arrangement_flow';
import { JsonViewer } from '@this/components/arrangement/virtual_counter/virtual_counter_aside/json_viewer';
import { HotelInfo } from '@this/components/arrangement/virtual_counter/virtual_counter_aside/hotel_info';
import { TicketReservation } from '@this/components/arrangement/virtual_counter/virtual_counter_aside/ticket_reservation';
import { Link } from '@this/shared/ui/navigations/link';
import type Trip from '@this/src/domain/trip/trip';

import type { TicketOprResponseArgs } from '@this/domain/ticket_opr_response';
import type { OrderItemStatusLogInfo } from '@this/components/arrangement/virtual_counter/types';
import { CancelArrangement } from './cancel_arrangement';
import { StatusChangeLog } from './status_change_log';
import { OrderInfo } from './order_info';
import { MemoFields } from './memo_fields';
import { PartnerDescription } from './partner_description';
import { TripInfo } from './trip_info';
import { TransportInfo } from './transport_info';
import { ReservationUrls } from './reservation_urls';
import { AioperatorInfo } from './aioperator_info';
import { RestartArrangement } from './restart_arrangement';

type Props = {
  className?: string;
  selectedTrip: Trip;
  ticketOprResponse: TicketOprResponseArgs[];
  planName: string;
  orderItemStatusLogInfo: OrderItemStatusLogInfo | null;
  reservationJson: React.ComponentProps<typeof TicketReservation>['reservationJson'];
  onReloadTrip: () => void;
} & Omit<React.ComponentProps<typeof ArrangementFlow>, 'className' | 'trip' | 'onReloadTrip'>;

export const VirtualCounterAside = ({
  className,
  selectedTrip,
  ticketOprResponse,
  planName,
  reservationJson,
  serviceId,
  editingOrder,
  shareholderTickets,
  bulkTickets,
  onReloadTrip,
  hotelElementProviderOptions,
  taxTypes,
  suppliedItems,
  paymentMethods,
  shareholderInfos,
  cabinOptions,
  individualTargetSuppliedItems,
  individualDomesticAirSuppliedItems,
  orderItemStatusLogInfo
}: Props) => {
  return (
    <Root className={className}>
      <VirtualCounterAsideSection>
        <OrderInfo trip={selectedTrip} />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <PartnerDescription trip={selectedTrip} planName={planName} />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <MemoFields trip={selectedTrip} />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <TripInfo trip={selectedTrip} />
        <TransportInfo
          trip={selectedTrip}
          showTicketOprTicketing={utils.isAiTravel(serviceId)}
          ticketOprResponse={ticketOprResponse}
          onReloadTrip={onReloadTrip}
        />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <AioperatorInfo trip={selectedTrip} />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <ReservationUrls trip={selectedTrip} />
      </VirtualCounterAsideSection>
      {selectedTrip.search_query && (
        <VirtualCounterAsideSection>
          <JsonViewer label="検索クエリ" json={selectedTrip.search_query} />
        </VirtualCounterAsideSection>
      )}
      <VirtualCounterAsideSection>
        <HotelInfo trip={selectedTrip} />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <TicketReservation reservationJson={reservationJson} />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <Link
          href={`/arrangement/order_histories?type=trip_id&trip_id=${selectedTrip.id}`}
          target="_blank"
          isExternal
        >
          この旅程の履歴を見る
        </Link>
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <ArrangementFlow
          trip={selectedTrip}
          serviceId={serviceId}
          editingOrder={editingOrder}
          shareholderTickets={shareholderTickets}
          bulkTickets={bulkTickets}
          hotelElementProviderOptions={hotelElementProviderOptions}
          taxTypes={taxTypes}
          suppliedItems={suppliedItems}
          paymentMethods={paymentMethods}
          shareholderInfos={shareholderInfos}
          cabinOptions={cabinOptions}
          individualTargetSuppliedItems={individualTargetSuppliedItems}
          individualDomesticAirSuppliedItems={individualDomesticAirSuppliedItems}
          onReloadTrip={onReloadTrip}
        />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <RestartArrangement trip={selectedTrip} onRestart={onReloadTrip} />
      </VirtualCounterAsideSection>
      <VirtualCounterAsideSection>
        <CancelArrangement trip={selectedTrip} onCancel={onReloadTrip} />
      </VirtualCounterAsideSection>
      {orderItemStatusLogInfo && (
        <VirtualCounterAsideSection>
          <StatusChangeLog orderItemStatusLogInfo={orderItemStatusLogInfo} />
        </VirtualCounterAsideSection>
      )}
    </Root>
  );
};

const Root = styled.div``;

export const VirtualCounterAsideSection = styled.section`
  padding: ${getSpacing(2)}px;
  &:empty {
    display: none;
  }

  & + & {
    border-top: 1px solid ${getColor('border', 'divider')};
  }
`;
