import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
/* eslint-disable max-lines */
import { Fetcher } from '@this/src/util';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import { getColor } from '@this/shared/ui/theme';
import { styled } from '@this/constants/themes';
import { Flex } from '@this/shared/ui/layout/flex';
import type { DateRangePickerValue } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import { DateRangePicker } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import type { MarketLogSummaryArgs } from '@this/src/domain/organization/market_log_summary';
import MarketLogSummary from '@this/src/domain/organization/market_log_summary';
import type { FlightMarketLogSummaryArgs } from '@this/src/domain/organization/flight_market_log_summary';
import FlightMarketLogSummary from '@this/src/domain/organization/flight_market_log_summary';
import { OrganizationTable, OrganizationTitle } from '../organization.style';
import SCButton from '../button';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

type DateType = 'trip' | 'start';
type Encoding = 'utf8' | 'sjis';
type AirType = 'domestic' | 'foreign';

const DATE_TYPES: Record<DateType, string> = {
  trip: '依頼日',
  start: '出発日'
};

const ENCODINGS: Record<Encoding, string> = {
  utf8: 'Macなど（文字コード：UTF8）',
  sjis: 'Windows（文字コード：SJIS）'
};

interface Props {
  serviceId: number;
}

interface State {
  marketLogs: MarketLogSummary[];
  flightMarketLogs: FlightMarketLogSummary[];
  dateType: DateType;
  fromDate: Moment | null;
  toDate: Moment | null;
  airType: AirType;
  encoding: Encoding;
  ready: boolean;
  loading: boolean;
  changedAirType: boolean;
}

type Action =
  | { type: 'SET_RESPONSE'; payload: MarketLogsResponse }
  | { type: 'SET_FLIGHT_RESPONSE'; payload: FlightMarketLogsResponse }
  | { type: 'SET_DATE_TYPE'; payload: DateType }
  | { type: 'SET_DATE_RANGE'; payload: DateRangePickerValue }
  | { type: 'SET_AIR_TYPE'; payload: AirType }
  | { type: 'SET_ENCODING'; payload: Encoding }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_CHANGED_AIR_TYPE'; payload: boolean };

const createInitialState = (): State => {
  const params = utils.getParams();
  const fromStr = utils.dig(params, 'from');
  const toStr = utils.dig(params, 'to');

  return {
    marketLogs: [],
    flightMarketLogs: [],
    dateType: utils.dig(params, 'dateType') || 'trip',
    fromDate: fromStr ? moment(fromStr) : moment().subtract(1, 'month'),
    toDate: toStr ? moment(toStr) : moment(),
    airType: utils.dig(params, 'airType') || 'domestic',
    encoding: utils.dig(params, 'encoding') || (navigator.platform.indexOf('Win') !== -1 ? 'sjis' : 'utf8'),
    ready: false,
    loading: true,
    changedAirType: false
  };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_RESPONSE':
      return {
        ...state,
        marketLogs: action.payload.market_logs.map(m => new MarketLogSummary(m)),
        ready: true,
        loading: false
      };
    case 'SET_FLIGHT_RESPONSE':
      return {
        ...state,
        flightMarketLogs: action.payload.market_logs.map(m => new FlightMarketLogSummary(m)),
        ready: true,
        loading: false
      };
    case 'SET_DATE_TYPE':
      return { ...state, dateType: action.payload };
    case 'SET_DATE_RANGE': {
      const [from, to] = action.payload;

      return {
        ...state,
        fromDate: from ? moment(from) : null,
        toDate: to ? moment(to) : null
      };
    }
    case 'SET_AIR_TYPE':
      return { ...state, airType: action.payload };
    case 'SET_ENCODING':
      return { ...state, encoding: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_CHANGED_AIR_TYPE':
      return { ...state, changedAirType: action.payload };
    default:
      return state;
  }
};

interface MarketLogsResponse {
  market_logs: MarketLogSummaryArgs[];
}

interface FlightMarketLogsResponse {
  market_logs: FlightMarketLogSummaryArgs[];
}

const MarketLogs: React.FC<Props> = ({ serviceId }) => {
  const initialState = useMemo(createInitialState, []);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { marketLogs, flightMarketLogs, dateType, fromDate, toDate, airType, ready, loading, changedAirType } =
    state;

  const params = useMemo(
    () => ({
      date_type: dateType,
      from: fromDate?.format('YYYY-MM-DD'),
      to: toDate?.format('YYYY-MM-DD'),
      air_type: airType
    }),
    [dateType, fromDate, toDate, airType]
  );

  const queries = useMemo(
    () =>
      Object.entries(params)
        .map(([k, v]) => `${k}=${v}`)
        .join('&'),
    [params]
  );

  const fetchMarketLogs = useCallback(() => {
    dispatch({ type: 'SET_LOADING', payload: true });
    dispatch({ type: 'SET_CHANGED_AIR_TYPE', payload: false });

    window.history.pushState({}, '', `${location.pathname}?${queries}`);

    if (params.air_type === 'domestic') {
      Fetcher.get<MarketLogsResponse>('/organization/market_logs.json', params)
        .then(payload => {
          dispatch({ type: 'SET_RESPONSE', payload });
        })
        .catch(error => {
          console.error(error);
          dispatch({ type: 'SET_LOADING', payload: false });
        });
    } else {
      Fetcher.get<FlightMarketLogsResponse>('/organization/market_logs.json', params)
        .then(payload => {
          dispatch({ type: 'SET_FLIGHT_RESPONSE', payload });
        })
        .catch(error => {
          console.error(error);
          dispatch({ type: 'SET_LOADING', payload: false });
        });
    }
  }, [params, queries]);

  const handleDateTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      dispatch({ type: 'SET_DATE_TYPE', payload: e.target.value as DateType });
    },
    [dispatch]
  );

  const handleDateRangeChange = useCallback(
    (value: DateRangePickerValue | null) => {
      dispatch({ type: 'SET_DATE_RANGE', payload: value ?? [null, null] });
    },
    [dispatch]
  );

  const handleAirTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'SET_CHANGED_AIR_TYPE', payload: true });
      dispatch({ type: 'SET_AIR_TYPE', payload: e.target.value as AirType });
    },
    [dispatch]
  );

  const handleEncodingChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      dispatch({ type: 'SET_ENCODING', payload: e.target.value as Encoding });
    },
    [dispatch]
  );

  useEffect(() => {
    // 初回レンダリング、または航空券タイプ変更後はデータをFetch
    if (!ready || changedAirType) fetchMarketLogs();
  }, [ready, fetchMarketLogs, changedAirType]);

  return (
    <>
      <OrganizationTitle>マーケットログサマリ</OrganizationTitle>
      <MarketLogsWrap>
        <div>
          <AirRadioInput
            id="radio-domestic"
            type="radio"
            name="airType"
            value="domestic"
            checked={airType === 'domestic'}
            onChange={e => {
              handleAirTypeChange(e);
            }}
          />
          <AirRadioInputLabel htmlFor="radio-domestic">国内航空券</AirRadioInputLabel>
          <AirRadioInput
            id="radio-foreign"
            type="radio"
            name="airType"
            value="foreign"
            checked={airType === 'foreign'}
            onChange={e => {
              handleAirTypeChange(e);
            }}
          />
          <AirRadioInputLabel htmlFor="radio-foreign">海外航空券</AirRadioInputLabel>
        </div>
        <SearchForm>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <DateArea>
                <Select value={dateType} onChange={handleDateTypeChange}>
                  {Object.entries(DATE_TYPES).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
                <DateRangePicker
                  value={[fromDate?.toDate() || null, toDate?.toDate() || null]}
                  onChange={handleDateRangeChange}
                  disabledClear
                />
                {dateType === 'trip' && <Comment>※弊社のシステムに依頼いただいた日付を基準としています。</Comment>}
              </DateArea>
            </Flex>
            <SCButton
              serviceId={serviceId}
              tag="input"
              type="submit"
              value="検索"
              width="80px"
              onClick={fetchMarketLogs}
            />
          </Flex>
        </SearchForm>
        <DownloadForm>
          <Select value={state.encoding} onChange={handleEncodingChange}>
            {Object.entries(ENCODINGS).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
          <SCButton
            serviceId={serviceId}
            value="CSVでダウンロード"
            tag="a"
            to={`/organization/market_logs.csv?${queries}`}
          />
        </DownloadForm>
        {airType === 'domestic' ? (
          // 国内航空券
          <OrganizationTable>
            <thead>
              <Tr isBgColor={false}>
                <Th>
                  <span>旅程番号</span>
                </Th>
                <Th>
                  <span>注文日</span>
                </Th>
                <Th>
                  <span>利用日</span>
                </Th>
                <Th>
                  <span>注文リードタイム</span>
                </Th>
                <Th>
                  <span>注文券種</span>
                </Th>
                <Th>
                  <span>注文金額</span>
                </Th>
                <Th>
                  <span>変更可_平均</span>
                </Th>
                <Th>
                  <span>変更可_最高値</span>
                </Th>
                <Th>
                  <span>変更可_最安値</span>
                </Th>
                <Th>
                  <span>変更可_中央値</span>
                </Th>
                <Th>
                  <span>変更不可_平均</span>
                </Th>
                <Th>
                  <span>変更不可_最高値</span>
                </Th>
                <Th>
                  <span>変更不可_最安値</span>
                </Th>
                <Th>
                  <span>変更不可_中央値</span>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={14}>
                    <SimpleLoading />
                  </td>
                </tr>
              ) : (
                marketLogs.map((marketLog, index) => (
                  <Tr key={index} isBgColor={index % 2 === 0}>
                    <Td>
                      <Strong>{marketLog.tripId}</Strong>
                    </Td>
                    <Td>{marketLog.receivedAt?.format('YYYY-MM-DD')}</Td>
                    <Td>{marketLog.startTime?.format('YYYY-MM-DD')}</Td>
                    <Td>{marketLog.leadTime ? `${marketLog.leadTime}日` : ''}</Td>
                    <Td>{marketLog.airChangeable ? '変更可' : '変更不可'}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.priceOrdered)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.changeable.average_price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.changeable.max_price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.changeable.min_price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.changeable.median_price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.unchangeable.average_price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.unchangeable.max_price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.unchangeable.min_price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.summary.unchangeable.median_price)}</Td>
                  </Tr>
                ))
              )}
            </tbody>
          </OrganizationTable>
        ) : (
          // 海外航空券
          <OrganizationTable>
            <thead>
              <Tr isBgColor={false}>
                <Th>
                  <span>旅程番号</span>
                </Th>
                <Th>
                  <span>注文日</span>
                </Th>
                <Th>
                  <span>利用日</span>
                </Th>
                <Th>
                  <span>注文リードタイム(日)</span>
                </Th>
                <Th>
                  <span>注文金額</span>
                </Th>
                <Th>
                  <span>同日_平均</span>
                </Th>
                <Th>
                  <span>同日_最高値</span>
                </Th>
                <Th>
                  <span>同日_最安値</span>
                </Th>
                <Th>
                  <span>同日_中央値</span>
                </Th>
                <Th>
                  <span>前日_平均</span>
                </Th>
                <Th>
                  <span>前日_最高値</span>
                </Th>
                <Th>
                  <span>前日_最安値</span>
                </Th>
                <Th>
                  <span>前日_中央値</span>
                </Th>
                <Th>
                  <span>翌日_平均</span>
                </Th>
                <Th>
                  <span>翌日_最高値</span>
                </Th>
                <Th>
                  <span>翌日_最安値</span>
                </Th>
                <Th>
                  <span>翌日_中央値</span>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={14}>
                    <SimpleLoading />
                  </td>
                </tr>
              ) : (
                flightMarketLogs.map((marketLog, index) => (
                  <Tr key={index} isBgColor={index % 2 === 0}>
                    <Td>
                      <Strong>{marketLog.tripId}</Strong>
                    </Td>
                    <Td>{marketLog.receivedAt?.format('YYYY-MM-DD')}</Td>
                    <Td>{marketLog.startTime?.format('YYYY-MM-DD')}</Td>
                    <Td>{marketLog.leadTime ? `${marketLog.leadTime}日` : ''}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.price)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.averagePrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.maxPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.minPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.marketLog.medianPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.yesterdayMarketLog.averagePrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.yesterdayMarketLog.maxPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.yesterdayMarketLog.minPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.yesterdayMarketLog.medianPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.tomorrowMarketLog.averagePrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.tomorrowMarketLog.maxPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.tomorrowMarketLog.minPrice)}</Td>
                    <Td>{utils.formatPrice(marketLog.tomorrowMarketLog.medianPrice)}</Td>
                  </Tr>
                ))
              )}
            </tbody>
          </OrganizationTable>
        )}
      </MarketLogsWrap>
    </>
  );
};

const MarketLogsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const SearchForm = styled.div`
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 3px;
  background: #f7f7f7;
  font-weight: bold;
`;

const DateArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  select {
    margin: 0;
  }
`;

const Select = styled.select`
  height: 25px;
  margin-right: 15px;
  margin-bottom: 0.75em;
`;

const Comment = styled.p`
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  font-weight: normal;
  margin-left: 15px;
`;

const DownloadForm = styled.div`
  display: flex;
  align-items: center;

  > select {
    margin-bottom: 0;
    margin-right: 8px;
  }
`;

const Tr = styled.tr<{ isBgColor: boolean }>`
  ${props =>
    props.isBgColor &&
    `
    background: ${props.theme.grayColorLight};
  `};
`;

const Th = styled.th`
  padding: 4px 8px;
  background: ${props => props.theme.grayColorLight};
  font-size: 12px;
  font-weight: normal;
  text-align: right;
  border-bottom: none;
  border-right: solid 1px white;
`;

const Td = styled.td`
  padding: 4px 8px;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  word-break: keep-all;
`;

const Strong = styled.span`
  color: ${props => props.theme.linkColor};
`;

const AirRadioInput = styled.input`
  && {
    display: none;
    + label {
      padding-left: 20px;
      position: relative;
      float: left;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
    &:checked {
      + label {
        color: ${getColor('brand', 'primary')};
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 4px;
          left: 2px;
          width: 11px;
          height: 11px;
          background: ${getColor('brand', 'primary')};
          border-radius: 50%;
        }
      }
    }
  }
`;

const AirRadioInputLabel = styled.label`
  font-weight: normal;
`;

export default MarketLogs;
/*  eslint-enable max-lines */
