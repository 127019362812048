import React from 'react';

import type { match } from 'react-router-dom';
import Link from '@this/components/shared/atoms/link';
import { Fetcher } from '@this/src/util';
import { reportError } from '@this/lib/bugsnag';

import {
  OrganizationBody,
  OrganizationHeader,
  OrganizationHeaderTitle,
  OrganizationTitle
} from '@this/components/organization/organization.style';
import BulkUpdateActionApprovers from '@this/components/organization/bulk_action_status/bulk_update_action_approvers';
import { styled } from '@this/constants/themes';
import BulkDeleteActionUsers from '@this/components/organization/bulk_action_status/bulk_delete_action_users';
import type { BulkActionStatusResponse } from '../types';
import BulkUpsertActionUsers from './bulk_upsert_action_users';
import BulkUpsertActionDepartments from './bulk_upsert_action_departments';
import BulkCreateActionProjects from './bulk_create_action_projects';
import BulkUpsertActionAccountTypes from './bulk_upsert_action_account_types';
import BulkUpsertActionExpensesTypes from './bulk_upsert_action_expenses_types';
import BulkUpdateActionProjectApprovers from './bulk_update_action_project_approvers';
import BulkUpsertActionExicCards from './bulk_upsert_action_exic_cards';

import BulkActionStatus from '../../../domain/bulk_action/bulk_action_status';

interface Props {
  match: match<{ id: string }>;
  expenses?: boolean;
}
interface State {
  status?: any;
  exicUsageMode?: string;
  loading: boolean;
  error?: string;
}
export default class BulkactionStatus extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { loading: false };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Fetcher.get<BulkActionStatusResponse>(`./${this.props.match.params.id}`)
      .then(result => {
        this.setState({
          loading: false,
          status: new BulkActionStatus(result.bulk_action_status),
          exicUsageMode: result?.organization?.exic_usage_mode || ''
        });
      })
      .catch(error => {
        const errorMessage = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        this.setState({
          loading: false,
          error: errorMessage
        });
        reportError(error);
      });
  }

  render() {
    const { status, loading, error, exicUsageMode } = this.state;
    const { expenses } = this.props;
    return (
      <>
        {expenses ? (
          <Header>
            <HeaderTitle>一括処理詳細</HeaderTitle>
          </Header>
        ) : (
          <OrganizationTitle>一括処理詳細</OrganizationTitle>
        )}
        <OrganizationBody>
          <Link
            to={`${expenses ? '/biztra' : ''}/organization/bulk_action_statuses`}
            className="organization-members__csv-back"
          >
            &lt; 戻る
          </Link>
          {!status || loading ? (
            <img className="select__loading" src="/images/loading.gif" width="50" height="50" />
          ) : error ? (
            <p>{error}</p>
          ) : status.isUpdateApprovers() ? (
            <BulkUpdateActionApprovers status={status} />
          ) : status.isUpdateProjectApprovers() ? (
            <BulkUpdateActionProjectApprovers status={status} />
          ) : status.isUpsertDepartments() ? (
            <BulkUpsertActionDepartments status={status} />
          ) : status.isCreateProjectsAction() ? (
            <BulkCreateActionProjects status={status} />
          ) : status.isUpsertAccountTypes() ? (
            <BulkUpsertActionAccountTypes status={status} />
          ) : status.isUpsertExpensesTypes() ? (
            <BulkUpsertActionExpensesTypes status={status} />
          ) : status.isUpsertUsers() ? (
            <BulkUpsertActionUsers status={status} expenses={expenses} exicUsageMode={exicUsageMode} />
          ) : status.isDeleteUsers() ? (
            <BulkDeleteActionUsers status={status} />
          ) : status.isUpsertExicCards() ? (
            <BulkUpsertActionExicCards status={status} />
          ) : (
            ''
          )}
        </OrganizationBody>
      </>
    );
  }
}

const Header = styled(OrganizationHeader)`
  padding: 0;
`;

const HeaderTitle = styled(OrganizationHeaderTitle)`
  color: #927230;
  font-size: 24px;
  margin: 0 0 12px 0;
`;
