import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { rgba } from 'polished';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type OrderItemTodo from '@this/src/domain/arrangement/order_item_todo';
import { styled } from '@this/src/components/constants/themes';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/components/shared/ui/inputs/button';
import { OrderItemCategory } from '@this/src/domain/order_item/order_item_category';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import _ from 'lodash';
import moment from 'moment';
import { FindTicketingTypeByValue } from '@this/domain/arrangement/ticketing_type_query';
import { OrderItemStatusForm } from '@this/components/arrangement/shared/price_change_form';
import TripStatusIndicator from '../../shared/trip_status_indicator';
import LogModal from '../log_modal';

interface Props {
  todo: OrderItemTodo;
  onMessageFinish: (messageId: number) => void;
  onSaveSnooze: (todo: OrderItemTodo) => void;
  onCancelSnooze: (todo: OrderItemTodo) => void;
  onSequenceOpen: (todo: OrderItemTodo) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSequenceAbortOpen: (todo: OrderItemTodo) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fetchOrderItemTodos: () => void;
  serviceId: number;
  onShowExtensionLogs: (traceId: string) => void;
}

const getDefaultSnoozeTo = () => {
  const target = moment().add(1, 'day');
  return moment({
    years: target.get('year'),
    months: target.get('month'),
    days: target.get('date'),
    hours: 9,
    minutes: 0
  });
};

const OrderItemTodoTableRow = observer(
  ({
    todo,
    onMessageFinish,
    onSaveSnooze,
    onCancelSnooze,
    onSequenceOpen,
    onSequenceAbortOpen,
    fetchOrderItemTodos,
    serviceId,
    onShowExtensionLogs
  }: Props) => {
    const [showLogModal, setShowLogModal] = useState<boolean>(false);
    const [editingSnooze, setEditingSnooze] = useState<OrderItemTodo | null>(null);

    return (
      <TableRow style={{ background: todo.isUrgent ? rgba('red', 0.3) : 'auto' }}>
        <TableCell nowrap>
          <TripStatusIndicator status={todo.tripStatus} />
        </TableCell>
        <TableCell>
          {todo.orderItemId && (
            <OrderItemStatusForm
              orderItemId={todo.orderItemId}
              jobType={todo.jobType}
              status={todo.status}
              onJobTypeChange={value => {
                todo.jobType = value;
              }}
              onStatusChange={value => {
                todo.status = value;
              }}
              showLabel={false}
              fetchData={fetchOrderItemTodos}
            />
          )}
        </TableCell>
        <TableCell>{todo.arrangerName}</TableCell>
        <TableCell nowrap>{todo.category !== undefined && OrderItemCategory[todo.category]}</TableCell>
        <TableCell>
          <TextWithChanged>
            {todo.currentStep?.sequenceName}
            <Button size="small" color="sub" onClick={onSequenceOpen(todo)}>
              {todo.currentStep ? '変更' : '追加'}
            </Button>
            {todo.currentStep && (
              <Button size="small" color="danger" onClick={onSequenceAbortOpen(todo)}>
                破棄
              </Button>
            )}
          </TextWithChanged>
        </TableCell>
        <TableCell>{todo.changedStatus ? '有り' : todo.changedStatus === false ? '無し' : null}</TableCell>
        <TableCell>{todo.organizationName}</TableCell>
        <TableCell>{todo.sp ? '◯' : ''}</TableCell>
        <TableCell>{todo.useQr ? '◯' : ''}</TableCell>
        <TableCell>{todo.premiumSupport ? '◯' : ''}</TableCell>
        <TableCell>{todo.waiting ? '◯' : ''}</TableCell>
        <TableCell>
          {todo.ticketingType.map((ticketing, i) => (
            <div key={i}>{FindTicketingTypeByValue(serviceId, ticketing)}</div>
          ))}
        </TableCell>
        <TableCell nowrap>{todo.startDate?.format('YYYY-MM-DD')}</TableCell>
        <TableCell nowrap>{todo.receivedAt?.format('YYYY-MM-DD HH:mm')}</TableCell>
        <TableCell>
          {todo.lastUserMessage?.split('\n').map((line, i) => (
            <Box key={i}>{line}</Box>
          ))}
        </TableCell>
        <TableCell nowrap>
          <Link
            href={`/arrangement/virtual_counter?trip_id=${todo.tripId}`}
            target="_blank"
            rel="noopener noreffer"
          >
            {todo.tripId}
          </Link>
        </TableCell>
        <TableCell nowrap>{todo.traceId}</TableCell>
        <TableCell>
          {todo.lastLogMessage && <span>{todo.lastLogMessage}</span>}
          {todo.lastLogMessageArranger && <span>({todo.lastLogMessageArranger})</span>}
          <p>
            (<Link onClick={() => setShowLogModal(true)}>ログ</Link>)
          </p>
        </TableCell>
        <TableCell>
          {todo.traceId && (
            <Button size="small" onClick={() => onShowExtensionLogs(String(todo.traceId))}>
              動作ログ
            </Button>
          )}
        </TableCell>
        <TableCell>
          {todo.todoType === 'message' && todo.lastUserMessageId ? (
            <Button size="small" onClick={() => todo.lastUserMessageId && onMessageFinish(todo.lastUserMessageId)}>
              対応不要
            </Button>
          ) : todo.todoType === 'order_item' ? (
            todo.snoozeTo ? (
              <>
                <Box>{todo.snoozeTo.format('YYYY-MM-DD HH:mm')}</Box>
                <Box>{todo.snoozeMessage}</Box>
                <Button size="small" onClick={() => onCancelSnooze(todo)}>
                  TODOに戻す
                </Button>
              </>
            ) : editingSnooze ? (
              <>
                <DatetimePicker
                  value={editingSnooze.snoozeTo}
                  onChange={d => {
                    editingSnooze.snoozeTo = d;
                  }}
                  showTime
                  showPast={false}
                  disabledDays={0}
                />
                <input
                  type="text"
                  placeholder="メモ"
                  value={editingSnooze.snoozeMessage}
                  onChange={e => {
                    editingSnooze.snoozeMessage = e.target.value;
                  }}
                />
                <Box display="flex">
                  <Button size="small" onClick={() => setEditingSnooze(null)}>
                    キャンセル
                  </Button>
                  <Button size="small" style={{ marginLeft: '10px' }} onClick={() => onSaveSnooze(editingSnooze)}>
                    保存
                  </Button>
                </Box>
              </>
            ) : (
              <Button
                size="small"
                onClick={() => {
                  const et = _.clone(todo);
                  et.snoozeTo = getDefaultSnoozeTo();
                  setEditingSnooze(et);
                }}
              >
                スヌーズ
              </Button>
            )
          ) : null}
        </TableCell>
        {showLogModal && todo.traceId && (
          <LogModal traceId={todo.traceId} onClose={() => setShowLogModal(false)} />
        )}
      </TableRow>
    );
  }
);

const TextWithChanged = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default OrderItemTodoTableRow;
