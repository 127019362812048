import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import { observable } from 'mobx';
import TransportElement from '@this/domain/transport_element';
import Traveler from '@this/domain/traveler/traveler';

export const TicketingMethod = {
  ekinet: 'えきねっと',
  biz_ekinet: 'ビジえき',
  qr_east: 'QR(JR東日本)',
  qr_west: 'QR(JR西日本)'
} as const;

export type TicketingMethodKey = keyof typeof TicketingMethod;
export const TicketingMethodKeys: TicketingMethodKey[] = ['ekinet', 'biz_ekinet', 'qr_east', 'qr_west'];

export const TicketingMethod2 = {
  all: '全て',
  ...TicketingMethod
} as const;

export type TicketingMethodKey2 = keyof typeof TicketingMethod2;
export const TicketingMethodKeys2: TicketingMethodKey2[] = ['all', ...TicketingMethodKeys];

export type TicketingInstructionResponseArgs = {
  order_item_id: number;
  trace_id: number;
  trip_id: number;
  start_date?: string;
  end_date?: string;
  received_at?: string;
  organization_name: string;
  use_qr?: boolean;
  premium_support?: boolean;
  latest_message: string;
  latest_log: string;
  content: string;
  ticketing_method: TicketingMethodKey | null;
  supplied_item_ids: (number | null)[];
  payment_method_ids: (number | null)[];
  ticketing_note?: string;
  reservation_numbers: string[];
  purchase_dates: (string | null)[];
  transport_elements?: TransportElement[];
  traveler_informations?: Traveler[];
};

export type TicketingInstructionArgs = {
  orderItemId: number;
  traceId: number;
  tripId: number;
  startDate?: Moment;
  endDate?: Moment;
  receivedAt: Moment;
  organizationName: string;
  useQr: boolean;
  premiumSupport: boolean;
  latestMessage: string;
  latestLog: string;
  content: string;
  ticketingMethod: TicketingMethodKey | null;
  suppliedItemIds: (number | null)[];
  paymentMethodIds: (number | null)[];
  ticketingNote?: string;
  reservationNumbers: string[];
  purchaseDates: (Moment | null)[];
  transportElements?: TransportElement[];
  travelerInformations?: Traveler[];
};

export const convertTicketingInstructionResponseToArgs = (
  response: TicketingInstructionResponseArgs
): TicketingInstructionArgs => {
  const args: TicketingInstructionArgs = {
    orderItemId: response.order_item_id,
    traceId: response.trace_id,
    tripId: response.trip_id,
    startDate: response.start_date ? moment(response.start_date) : undefined,
    endDate: response.end_date ? moment(response.end_date) : undefined,
    receivedAt: moment(response.received_at),
    organizationName: response.organization_name,
    useQr: response.use_qr || false,
    premiumSupport: response.premium_support || false,
    latestMessage: response.latest_message,
    latestLog: response.latest_log,
    content: response.content,
    ticketingMethod: response.ticketing_method as TicketingMethodKey,
    suppliedItemIds: response.supplied_item_ids,
    paymentMethodIds: response.payment_method_ids,
    ticketingNote: response.ticketing_note,
    reservationNumbers: response.reservation_numbers,
    purchaseDates: response.purchase_dates?.map(date => (date ? moment(date) : moment())) || [],
    transportElements: (response.transport_elements || []).map(args => new TransportElement(args)),
    travelerInformations: (response.traveler_informations || []).map(args => new Traveler(args))
  };
  return args;
};

export default class TicketingInstruction {
  @observable readonly orderItemId: number;

  @observable readonly traceId: number;

  @observable readonly tripId: number;

  @observable readonly startDate?: Moment;

  @observable readonly endDate?: Moment;

  @observable readonly receivedAt: Moment;

  @observable readonly organizationName: string;

  @observable readonly useQr: boolean;

  @observable readonly premiumSupport: boolean;

  @observable readonly latestMessage: string;

  @observable readonly latestLog: string;

  @observable readonly content: string;

  @observable ticketingMethod: TicketingMethodKey;

  @observable suppliedItemIds: (number | null)[];

  @observable paymentMethodIds: (number | null)[];

  @observable ticketingNote?: string;

  @observable reservationNumbers: string[];

  @observable purchaseDates: (Moment | null)[];

  @observable transportElements?: TransportElement[];

  @observable travelerInformations?: Traveler[];

  constructor(args: TicketingInstructionArgs) {
    this.orderItemId = args.orderItemId;
    this.traceId = args.traceId;
    this.tripId = args.tripId;
    this.startDate = args.startDate;
    this.endDate = args.endDate;
    this.receivedAt = args.receivedAt;
    this.organizationName = args.organizationName;
    this.useQr = args.useQr;
    this.premiumSupport = args.premiumSupport;
    this.latestMessage = args.latestMessage;
    this.latestLog = args.latestLog;
    this.content = args.content;
    this.ticketingMethod = args.ticketingMethod === null ? 'qr_east' : args.ticketingMethod;
    this.suppliedItemIds = args.suppliedItemIds;
    this.paymentMethodIds = args.paymentMethodIds;
    this.ticketingNote = args.ticketingNote;
    this.reservationNumbers = args.reservationNumbers;
    this.purchaseDates = args.purchaseDates;
    this.transportElements = args.transportElements;
    this.travelerInformations = args.travelerInformations;
  }
}
