import { Fetcher } from '@this/src/util';
/* eslint-disable max-lines */
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { styled } from '@this/constants/themes';
import { Button, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import type Project from '@this/src/domain/project/project';
import type ExpensesType from '@this/src/domain/expenses/expenses_type';
import type TaxType from '@this/src/domain/tax_type';
import type { TripType } from '@this/src/domain/expenses/item';
import type { ExpenseTool } from '@this/src/domain/setting';
import type {
  CalcPriceOrder,
  CalcPriceOrderOptions,
  NonOrderItemForReport,
  TripTypeOptions
} from '@this/src/domain/trip_report/non_order_item';
import DatetimePicker from '@this/src/components/shared/datetime_picker/datetime_picker';
import EkispertInput from '@this/src/components/shared/autocompletable_input/ekispert_input';
import CheckboxField from '@this/src/components/shared/form_elements/checkbox_field';
import RadioField from '@this/src/components/shared/form_elements/radio_field';

type Props = {
  changePrice: boolean;
  item: NonOrderItemForReport;
  index: number;
  expenseTool?: ExpenseTool;
  projectOptions: Project[];
  expensesTypeOptions: ExpensesType[];
  tripTypeOptions: TripTypeOptions;
  calcPriceOrderOptions: CalcPriceOrderOptions;
  taxTypeOptions: TaxType[];
  setChangePrice: Dispatch<SetStateAction<boolean>>;
  removeNonOrderItem: () => void;
};

interface EkispertCourse {
  price: number;
  route: string;
  distance: number;
}

interface EkispertPriceResponse {
  courses: EkispertCourse[];
}

export class NonOrderItemsFormStore {
  calcPriceOrders: Record<string, CalcPriceOrder> = {};

  useIcs: Record<string, boolean> = {};

  mount(serial: string) {
    if (Object.keys(this.calcPriceOrders).includes(serial)) return;

    this.calcPriceOrders[serial] = 'price';
    this.useIcs[serial] = true;
    app.render();
  }

  calcPriceOrder(serial: string) {
    return this.calcPriceOrders[serial] ?? 'price';
  }

  useIc(serial: string) {
    return this.useIcs[serial] ?? true;
  }

  setCalcPriceOrder(serial: string, value: CalcPriceOrder) {
    this.calcPriceOrders[serial] = value;
    app.render();
  }

  setUseIc(serial: string, value: boolean) {
    this.useIcs[serial] = value;
    app.render();
  }
}

export const NonOrderItemsFormContext = createContext<NonOrderItemsFormStore>(
  null as unknown as NonOrderItemsFormStore
);

export const useNonOrderItemsFormStore = () => useContext(NonOrderItemsFormContext);

export const TripReportTripSelectedNonOrderItemsForm: React.FC<Props> = ({
  changePrice,
  item,
  index,
  expenseTool,
  projectOptions,
  expensesTypeOptions,
  tripTypeOptions,
  calcPriceOrderOptions,
  taxTypeOptions,
  setChangePrice,
  removeNonOrderItem
}) => {
  const projectable = useMemo(() => projectOptions.length > 0, [projectOptions]);
  const store = useNonOrderItemsFormStore();
  const calcPriceOrder = store.calcPriceOrder(item.serial);
  const useIc = store.useIc(item.serial);
  const colSpan = useMemo(
    () => 5 + (projectable ? 1 : 0) + (expenseTool !== 'none' ? 1 : 0),
    [projectable, expenseTool]
  );
  const expensesTaxType = useMemo(() => {
    return taxTypeOptions.find(t => t.id === item.expensesType?.taxTypeId);
  }, [taxTypeOptions, item.expensesType?.taxTypeId]);

  useEffect(() => {
    store.mount(item.serial);
  }, [item.serial]);

  const fetchPrice = useCallback(
    async (_funcName: string, props: { useIc?: boolean; order?: CalcPriceOrder } = {}) => {
      if (item.fromName && item.toName && item.time && item.tripType) {
        const params = {
          from: item.fromName,
          to: item.toName,
          date: item.time.format('YYYY/MM/DD'),
          trip_type: item.tripType,
          sort: props.order ?? calcPriceOrder,
          use_ic: props.useIc ?? useIc
        };
        const q = utils.toParams(params);
        const res = await Fetcher.get<EkispertPriceResponse>(`/biztra/ekispert/price?${q}`);
        const course = res.courses[0];
        if (course) {
          item.setField('price', course.price);
          if (item.expensesType?.isDailyAllowance()) {
            item.setField(
              'memo',
              `${course.route}\n直線距離: ${new Intl.NumberFormat().format(course.distance)}m`
            );
          } else {
            item.setField('memo', course.route);
          }
        }
      }
    },
    [item, calcPriceOrder, useIc]
  );

  return (
    <>
      {expenseTool !== 'none' && (
        <TableCell style={{ verticalAlign: 'baseline' }}>
          <Value>
            <ExpensesTypeSelect
              value={item.expensesTypeId?.toString() || ''}
              onChange={e =>
                item.setExpensesType(expensesTypeOptions.find(option => option.id.toString() === e.target.value))
              }
            >
              <option value="">勘定科目：経費科目を選択</option>
              {expensesTypeOptions.map(expensesType => (
                <option key={expensesType.id} value={expensesType.id.toString()}>
                  {expensesType.expensesAccountType?.name}：{expensesType.name}
                </option>
              ))}
            </ExpensesTypeSelect>
          </Value>
          {item.validationErrors.expensesTypeId && (
            <ExpensesError>{item.validationErrors.expensesTypeId}</ExpensesError>
          )}
          {item.needParticipants() && (
            <>
              <ExpensesTitle>参加企業</ExpensesTitle>
              <ExpensesItemRow>
                <Label>合計人数：{item.totalPeopleNum()}</Label>
                <Label>平均単価：{item.avaragePeopleUnit() || '--'}円</Label>
              </ExpensesItemRow>
              <ExpensesItemRow>
                <Label>自社の参加人数</Label>
                <Input
                  type="number"
                  value={item.peopleNum}
                  width="120px"
                  onChange={e => item.setField('peopleNum', parseInt(e.target.value, 10))}
                />
              </ExpensesItemRow>
              {item.validationErrors.peopleNum && <Error>{item.validationErrors.peopleNum}</Error>}
              {item.participants.map((participant, i) => (
                <ExpensesItemRow key={`participant-${i}`}>
                  <Input
                    type="text"
                    value={participant.name}
                    width="120px"
                    placeholder="企業名"
                    onChange={e => participant.setField('name', e.target.value)}
                  />
                  <Label>人数</Label>
                  <Input
                    type="number"
                    value={participant.peopleNum}
                    width="40px"
                    onChange={e => participant.setField('peopleNum', parseInt(e.target.value, 10))}
                  />
                  <IconButton size="small" color="secondary" onClick={() => item.removeParticipant(i)}>
                    <DeleteIcon />
                  </IconButton>
                </ExpensesItemRow>
              ))}
              {item.validationErrors.participants && (
                <ExpensesError>{item.validationErrors.participants}</ExpensesError>
              )}
              <Button variant="contained" color="primary" size="small" onClick={() => item.addParticipant()}>
                追加
              </Button>
            </>
          )}
          {item.travelExpenses() && (
            <>
              <ExpensesItemRow>
                <Label>出発</Label>
                <EkispertInput
                  example="駅名・ビル名など"
                  onChange={(_name, value, _address) => item.setField('fromName', value)}
                  onDisappearSuggest={fetchPrice}
                  value={item.fromName || ''}
                  freeInput={!item.expensesType?.isTrain()}
                  id="origin"
                />
              </ExpensesItemRow>
              {item.validationErrors.fromName && <ExpensesError>{item.validationErrors.fromName}</ExpensesError>}
              <ExpensesItemRow>
                <Label>到着</Label>
                <EkispertInput
                  example="駅名・ビル名など"
                  onChange={(_name, value, _address) => item.setField('toName', value)}
                  onDisappearSuggest={fetchPrice}
                  value={item.toName || ''}
                  freeInput={!item.expensesType?.isTrain()}
                  id="destination"
                />
              </ExpensesItemRow>
              {item.validationErrors.toName && <ExpensesError>{item.validationErrors.toName}</ExpensesError>}
              {item.expensesType?.isTrain() && (
                <>
                  <ExpensesItemRow>
                    <CheckboxField
                      label="ICカード優先"
                      checked={useIc}
                      onChange={() => {
                        const checked = !useIc;
                        store.setUseIc(item.serial, checked);
                        fetchPrice('', { useIc: checked });
                      }}
                      id={`non_order_item-${index}-use_ic`}
                    />
                    {tripTypeOptions.map(([label, v]) => (
                      <RadioField
                        key={v}
                        label={label}
                        value={v}
                        checked={item.tripType === v}
                        onChange={value => {
                          item.setField('tripType', value as TripType);
                          fetchPrice('');
                        }}
                        id={`non_order_item-${index}-trip_type-${v}`}
                      />
                    ))}
                  </ExpensesItemRow>
                  <ExpensesItemRow>
                    {calcPriceOrderOptions.map(([label, v]) => (
                      <RadioField
                        key={v}
                        label={label}
                        value={v}
                        checked={calcPriceOrder === v}
                        onChange={value => {
                          store.setCalcPriceOrder(item.serial, value as CalcPriceOrder);
                          fetchPrice('', { order: value as CalcPriceOrder });
                        }}
                        id={`non_order_item-${index}-calc_price_order-${v}`}
                      />
                    ))}
                  </ExpensesItemRow>
                  <ExpensesItemRow>
                    <Label>利用鉄道名</Label>
                    <Input
                      type="text"
                      value={item.railwayName}
                      width="160px"
                      onChange={e => item.setField('railwayName', e.target.value)}
                    />
                  </ExpensesItemRow>
                  {item.validationErrors.railwayName && (
                    <ExpensesError>{item.validationErrors.railwayName}</ExpensesError>
                  )}
                </>
              )}
            </>
          )}
          {item.expensesType?.isTaxi() && (
            <ExpensesItemRow>
              {tripTypeOptions.map(([label, v]) => (
                <RadioField
                  key={v}
                  label={label}
                  value={v}
                  checked={item.tripType === v}
                  onChange={value => item.setField('tripType', value as TripType)}
                  id={`non_order_item-${index}-trip_type-${v}`}
                />
              ))}
            </ExpensesItemRow>
          )}
        </TableCell>
      )}
      <TableCell colSpan={colSpan}>
        <NonOrderItemForm>
          <NonOrderItemRow>
            <Value>
              {item.availableTime() ? (
                <DatetimePicker
                  value={item.time || undefined}
                  onChange={value => item.setField('time', value)}
                  showPast
                  border
                  placeholder="日付を選択"
                />
              ) : (
                item.getPeriod()
              )}
            </Value>
            {item.validationErrors.time && <Error>{item.validationErrors.time}</Error>}
          </NonOrderItemRow>
          <NonOrderItemRow>
            <Input
              type="text"
              value={item.payee}
              onChange={e => item.setField('payee', e.target.value)}
              placeholder={item.travelExpenses() ? '訪問先' : '支払先'}
            />
            {item.validationErrors.payee && <Error>{item.validationErrors.payee}</Error>}
          </NonOrderItemRow>
          <NonOrderItemRow>
            <Input
              type="number"
              value={item.price?.toString() || ''}
              onChange={e => item.setField('price', e.target.value !== '' ? parseInt(e.target.value, 10) : null)}
              placeholder="金額"
            />
            {item.validationErrors.price && <Error>{item.validationErrors.price}</Error>}
          </NonOrderItemRow>
          <NonOrderItemRow>
            <Select
              className="input"
              value={item.taxTypeId?.toString() || ''}
              onChange={e =>
                item.setField('taxTypeId', e.target.value !== '' ? parseInt(e.target.value, 10) : undefined)
              }
              placeholder="税区分"
            >
              {/* <option value="">税区分を選択</option> */}
              <option value="">
                {expensesTaxType ? `${expensesTaxType.name} [${item.expensesType?.name}の設定]` : '税区分を選択'}
              </option>
              {taxTypeOptions.map(taxType => (
                <option key={taxType.id} value={taxType.id.toString()}>
                  {taxType.name}
                </option>
              ))}
            </Select>
          </NonOrderItemRow>
          {projectable && (
            <NonOrderItemRow>
              <Select
                className="input"
                value={item.projectId?.toString() || ''}
                onChange={e =>
                  item.setProject(projectOptions.find(option => option.id.toString() === e.target.value))
                }
              >
                <option value="">プロジェクトを選択</option>
                {projectOptions.map(project => (
                  <option key={project.id} value={project.id.toString()}>
                    {project.code} - {project.name}
                  </option>
                ))}
              </Select>
              {item.validationErrors.projectId && <Error>{item.validationErrors.projectId}</Error>}
            </NonOrderItemRow>
          )}
          <NonOrderItemRow>
            <Textarea
              value={item.memo || item.elementName || ''}
              onChange={e => item.setField('memo', e.target.value)}
              placeholder="備考"
              rows={3}
            />
            {item.validationErrors.memo && <Error>{item.validationErrors.memo}</Error>}
          </NonOrderItemRow>
          <Actions>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => (item.adding ? removeNonOrderItem() : item.setEditing(false, { cancel: true }))}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                item.setEditing(false);
                setChangePrice(!changePrice);
              }}
            >
              保存
            </Button>
          </Actions>
        </NonOrderItemForm>
      </TableCell>
    </>
  );
};

const Error = styled.div`
  color: ${props => props.theme.redColor};
  margin-top: -0.75em;
  margin-bottom: 0.75em;
  padding: 0 8px;
`;

const ExpensesError = styled.div`
  color: ${props => props.theme.redColor};
  margin-top: -0.75em;
  margin-bottom: 0.75em;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const Value = styled.div`
  margin-right: 10px;
  margin-bottom: 0.75em;
  font-size: 13px;
`;

const Input = styled.input<{ width?: string }>`
  &&& {
    margin-right: 10px;
    font-size: 13px;
    ${props =>
      props.width &&
      `
      width: ${props.width};
      max-width: 100%;
    `}

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const Textarea = styled.textarea`
  &&& {
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const Select = styled.select`
  &&& {
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }

    &:focus-visible {
      outline: none;
    }
  }
`;

const NonOrderItemForm = styled.form`
  max-width: 400px;
`;

const NonOrderItemRow = styled.div``;

const ExpensesTitle = styled.h4`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.grayTextColor};
  margin-top: 12px;
  margin-bottom: 4px;
`;

const ExpensesItemRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > * {
    margin-bottom: 0.75em;
  }

  .form-elements-text-field {
    .form-elements-text-field {
      &__label {
        display: none;
      }

      &__input[type='text'] {
        padding: 8px;
        border: 1px solid #eee;
        box-shadow: inset 0 1px 3px rgb(0 0 0 / 6%);

        &:hover {
          border-color: #d5d5d5;
        }

        &:focus {
          box-shadow: none;
          border-color: ${props => props.theme.linkColor};
        }
      }
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const ExpensesTypeSelect = styled.select`
  padding: 4px;
  border-color: #ccc;
  border-radius: 4px;
  margin-bottom: 0;
`;
