HotelElementGuest = require('./hotel_element_guest').default
HotelReservation = require('./hotel_reservation').default
CancelLimit = require('./cancel_limit').default
moment = require('moment-timezone')

toDomesticTel = require('../util/string').toDomesticTel

class HotelElement
  constructor: (args = {}) ->
    args = snakeToCamelObject(args)
    _.assign(@, args)
    @type = 'hotel'
    @checkinDate = moment(args.checkinDate)
    @checkoutDate = if args.checkoutDate then moment(args.checkoutDate) else @checkinDate.add(1, 'day')
    @peopleNum = args.peopleNum
    @latitude = args.latitude
    @longitude = args.longitude
    @roomNum ||= 1
    @name ||= ''
    @roomType ||= ''
    @bedType ||= ''
    @provider ||= 'ean'
    @address ||= ''
    @tel ||= ''
    @planName ||= ''
    @breakfast =
      switch args.breakfast
        when true then 'true'
        when false then 'false'
        else 'null'
    @refundable =
      switch args.refundable
        when true then 'true'
        when false then 'false'
        else 'null'
    @rawId = args.rawId
    @eanItineraryId = args.eanItineraryId
    @rapidItineraryId = args.rapidItineraryId
    @apiCanceledAt =  if args.apiCanceledAt then moment(args.apiCanceledAt) else null
    @eanConfirmationNumber = args.eanConfirmationNumber
    @eanAffiliateConfirmationId = args.eanAffiliateConfirmationId
    @marketlogAvailable = args.marketlogAvailable
    @hotelElementGuests =
      _.map(args.hotelElementGuests, (raw) -> new HotelElementGuest(raw))
    @hotelReservation = if args.hotelReservation then new HotelReservation(args.hotelReservation)
    @mynaviReservationNumber =
      if args.mynaviBookingInfo then args.mynaviBookingInfo.reservationNo else '-'
    @initialData = _.cloneDeep(@)
    @hotelReservationNumber = args.hotelReservationNumber || ''
    @rakutenTravelRConnectBookingInfos =
      _.map(args.rakutenTravelRConnectBookingInfos, (raw) -> raw)
    @detailPath = args.detailPath
    @cancelLimits = if args.cancelLimits then _.map(args.cancelLimits, (raw) -> new CancelLimit(raw)) else []

  handleNameChange: (value) ->
    @name = value
    app.render()

  handlePlanNameChange: (value) ->
    @planName = value
    app.render()

  handleRoomTypeChange: (value) ->
    @roomType = value
    app.render()

  handleBedTypeChange: (value) ->
    @bedType = value
    app.render()

  handleCheckinDateChange: (moment) ->
    @checkinDate = _.cloneDeep(moment)
    nextDay = moment.add(1, 'day')
    @checkoutDate = nextDay if @checkoutDate <= nextDay
    app.render()

  handleCheckoutDateChange: (moment) ->
    @checkoutDate = _.cloneDeep(moment)
    prevDay = moment.add(-1, 'day')
    @checkinDate = prevDay if @checkinDate >= prevDay
    app.render()

  handleHotelProviderChange: (value) ->
    @provider = value
    app.render()

  handlePeopleNumChange: (e) ->
    e.preventDefault()
    @peopleNum = e.target.value
    app.render()

  handleRoomNumChange: (value) ->
    @roomNum = parseInt(value)
    app.render()

  handleAddressChange: (value) ->
    @address = value
    app.render()

  handleTelChange: (value) ->
    @tel = value
    app.render()

  handleBreakfastChange: (value) ->
    @breakfast = value
    app.render()

  handleRefundableChange: (value) ->
    @refundable = value
    app.render()

  handleGuestLastNameChange: (value) ->
    @guestLastName = value
    app.render()

  handleGuestFirstNameChange: (value) ->
    @guestFirstName = value
    app.render()

  handleGuestTelChange: (value) ->
    @guestTel = value
    app.render()

  handleHotelReservationNumberChange: (value) ->
    @hotelReservationNumber = value
    app.render()

  handleIsForeignChange: (value) ->
    @isForeign = value
    app.render()

  addCancelLimit: (limit) ->
    @cancelLimits.push(limit)
    app.render()

  removeCancelLimit: (limit) ->
    @cancelLimits = _.reject(@cancelLimits, (l) -> l == limit)
    app.render()

  startDate: ->
    @checkinDate

  endDate: ->
    @checkoutDate

  staySchedule: ->
    "#{@checkinDate.format('YYYY-MM-DD')} 〜 #{@checkoutDate.format('YYYY-MM-DD')}"

  stayDays: ->
    @checkoutDate.diff(@checkinDate, 'days')

  title: ->
    '宿泊'

  summary: ->
    "#{@checkinDate.format('YYYY-MM-DD')} 〜 #{@checkoutDate.format('YYYY-MM-DD')}"

  dateStr: ->
    "#{@checkinDate.format('YYYY-MM-DD')}〜#{@checkoutDate.format('YYYY-MM-DD')}"

  detail: ->
    lines = this.structuredDetail()
    s = ''
    for line in lines
      s += line.label if line.label
      s += line.value if line.value
      s += '\n'
    s

  structuredDetail: ->
    lines = this.structuredHotelTypeDetail()
    lines.push(this.structuredRoomTypeDetail())

    lines

  hotelTypeDetail: ->
    lines = this.structuredHotelTypeDetail()
    s = ''
    for line in lines
      s += line.label if line.label
      s += line.value if line.value
      s += '\n'
    s

  structuredHotelTypeDetail: ->
    lines = []
    lines.push(
      {
        label: '宿泊プラン：',
        value: if _.isEmpty(@planName) then '-' else @planName,
        translate: true
      },
    )
    unless @provider == 'agoda'
      lines.push(
        {
          label: '部屋タイプ：',
          value: if _.isEmpty(@roomType) then '' else @roomType,
          translate: true
        }
      )
    lines.push(
      {
        label: '',
        value: @breakfastDescription(),
        translate: true
      }
    )
    lines

  roomTypeDetail: ->
    lines = this.structuredRoomTypeDetail()
    s = ''
    for line in lines
      s += line.label if line.label
      s += line.value if line.value
      s += "\n"
    s

  structuredRoomTypeDetail: ->
    lines = []

    _.map(@hotelElementGuests, (guest, i) ->
      lines.push(
        {
          label: '',
          value: '<' + (i + 1) + '室目>',
          translate: false
        },
        {
          label: '',
          value: guest.bedTypeDescriptionOverview(),
          translate: false
        },
        {
          label: '',
          value: guest.guestLastName + ' ' + guest.guestFirstName + ' (' + guest.guestTel + ')',
          translate: false
        }
      )
    )
    lines

  mappingDescription: ->
    "ホテル：#{@name}"

  hotelMapAddressLink: ->
    if !_.isEmpty(@address)
      "https://www.google.com/maps/search/#{@address}"
    else if !_.isEmpty(@latitude) && !_.isEmpty(@longitude)
      "https://www.google.com/maps/search/#{@latitude}, #{@longitude}"

  providerString: ->
    switch @provider
      when 'ean' then 'EAN'
      when 'rakuten' then '(旧)楽天トラベル'
      when 'new_rakuten' then '楽天トラベル'
      when 'jaran' then 'じゃらん'
      when 'expedia' then 'Expedia'
      when 'wbf' then 'WBF'
      when 'eps_rapid' then 'Eps Rapid'
      when 'jr' then 'JR'
      when 'ana_tabisaku' then 'ANA旅作'
      when 'racco' then 'Racco'

  addressDescription: ->
    if _.isEmpty(@address)
      ''
    else
      "\n住所：#{@address}"

  telDescription: ->
    if _.isEmpty(@tel)
      ''
    else
      "\n電話番号：#{toDomesticTel(@tel)}"

  planNameDescription: ->
    if _.isEmpty(@planName)
      '宿泊プラン：-'
    else
      "\n宿泊プラン：#{@planName}"

  roomTypeDescription: ->
    if _.isEmpty(@roomType)
      ''
    else
      "\n部屋タイプ：#{@roomType}"

  bedTypeDescription: ->
    if _.isEmpty(@bedType)
      '\nベッドタイプ：-'
    else
      "\nベッドタイプ：#{@bedType}"

  breakfastDescription: ->
    d = '\n朝食：'
    d +=
      switch @breakfast
        when 'true' then '有り'
        when 'false' then '無し'
        when 'null' then 'お問合せください'
    d

  refundableDescription: ->
    return '' if @isPackage()

    d = '\n返金：'
    d +=
      switch @refundable
        when 'true', true
          '可'
        when 'false', false
          '不可'
        else
          'お問合せください'
    d

  hotelReservationNumberDescription: ->
    if _.isEmpty(@hotelReservationNumber)
      ''
    else
      "\nホテル予約番号：#{@hotelReservationNumber}"

  description: ->
    d = """
    <宿泊>
    ■ご利用：#{@peopleNum}名#{@roomNum}室
    ■日程
    #{@staySchedule()}
    #{@stayDays()}泊
    ■ホテル
    #{@name}
    """
    d +=
      @planNameDescription() +
        @roomTypeDescription() +
        @breakfastDescription() +
        @refundableDescription() +
        @addressDescription() +
        @telDescription() +
        @hotelReservationNumberDescription()

    d += _.map(@hotelElementGuests, (guest, i) ->
      """


      <#{i + 1}室目>
      #{guest.bedTypeDescriptionOverview()}
      ■宿泊代表者
      #{guest.guestLastName} #{guest.guestFirstName}
      #{guest.guestTel}
      """
    )

    d

  marketLogType: ->
    @type

  isAir: ->
    false

  isRailway: ->
    false

  isHotel: ->
    true

  isPackage: ->
    !!@packageType || @provider == 'wbf'

  isCancelable: (orderItemCategory) ->
    return false　if @provider != 'mynavi' || orderItemCategory == 'cancel' || @apiCanceledAt != null
    @checkinDate >= moment.tz('Asia/Tokyo').add(1, 'day').startOf('day')

  isCanceled: (orderItemCategory) ->
    return @provider == 'mynavi' && @apiCanceledAt != null

  validationErrors: (orderItemStatus) ->
    errors = []
    needStatusGuard = orderItemStatus == 7 || orderItemStatus == 13
    if @name == '' then errors.push(@hotelValidationError)
    if @checkoutDate < @checkinDate then errors.push(@timeValidationError)
    if @hotelReservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
    errors = errors.concat _.flatMap(@cancelLimits, (limit) -> limit.validationErrors())
    errors

  hotelValidationError:
    'ホテル名を入力してください。\n予約が不要な場合、\nホテルの内容を削除してください。'

  timeValidationError:
    'チェックイン以降を指定してください。'

  reservationNumberValidationError:
    '予約番号を入力してください。'

  updateParams: ->
    type: @type
    checkin_date: @checkinDate.format('YYYY-MM-DD')
    checkout_date: @checkoutDate.format('YYYY-MM-DD')
    name: @name
    provider: @provider
    people_num: @peopleNum
    room_num: @roomNum
    plan_name: @planName
    room_type: @roomType
    bed_type: @bedType
    address: @address
    tel: @tel
    breakfast:
      switch @breakfast
        when 'true' then true
        when 'false' then false
        when 'null' then null
    refundable:
      switch @refundable
        when 'true' then true
        when 'false' then false
        when 'null' then null
    guest_last_name: @guestLastName
    guest_first_name: @guestFirstName
    guest_tel: @guestTel
    raw_id: @rawId
    ean_itinerary_id: @eanItineraryId
    rapid_itinerary_id: @rapidItineraryId
    ean_confirmation_number: @eanConfirmationNumber
    ean_affiliate_confirmation_id: @eanAffiliateConfirmationId
    api_canceled_at: @apiCanceledAt?.format()
    hotel_reservation: @hotelReservation
    hotel_reservation_number: @hotelReservationNumber
    is_foreign: @isForeign
    rakuten_hotel_reservation_ids: _.map(@rakutenTravelRConnectBookingInfos, (info) -> info['hotel_reservation_id'])
    cancel_limits_attributes: _.map(@cancelLimits, (limit) -> limit.updateParams())

  restore: ->
    _.assign(@, _.cloneDeep(@initialData))

module.exports = HotelElement
