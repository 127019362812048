/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import type {
  TicketingInstructionResponseArgs,
  TicketingMethodKey2
} from '@this/src/domain/arrangement/ticketing_instruction';
import TicketingInstruction, {
  convertTicketingInstructionResponseToArgs,
  TicketingMethod,
  TicketingMethod2,
  TicketingMethodKeys2
} from '@this/src/domain/arrangement/ticketing_instruction';
import type { UseQrQueryKey } from '@this/src/domain/organization/use_qr_query';
import { UseQrQuery, UseQrQueryKeys } from '@this/src/domain/organization/use_qr_query';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/components/shared/ui/data_displays/table';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher, HTTPError } from '@this/src/util';
import { TransportElementAttachFileForm } from '@this/components/arrangement/shared/price_change_form/price_change_form_item/transport_element_form/transport_element_attach_file_form';
import { styled } from '@this/constants/themes';
import Notification from '@this/src/notification';
import TransportElementAttachFile from '@this/src/domain/transport_element_attach_file';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import Confirm from '@this/shared/confirm/confirm';
import type { TabType } from '../todo_list';
import { SearchArea, SearchBlock, SearchLabel } from '../search_area';
import useOrganizationSelector from '../use_organization_selector';

type SortKey = 'organization' | 'received_at' | 'started_at';
type SortDirection = 'asc' | 'desc';
type UploadFileData = {
  [traceId: string]: {
    [transportElementId: string]: {
      [travelerIdKey: string]: Array<
        | {
            id: number;
            transport_element_id: number;
            traveler_information_id: string | null;
            trip_id: number;
            upload_file_name: string;
            content_type: string;
            file_path: string;
            created_at: string;
            updated_at: string;
            upload_file_base64?: never; // 登録済みファイルはBase64を持たない
          }
        | {
            upload_file_name: string;
            content_type: string;
            traveler_information_id: string | null;
            upload_file_base64: string;
            // 新規ファイルには持たない項目
            id?: never;
            transport_element_id?: never;
            trip_id?: never;
            file_path?: never;
            created_at?: never;
            updated_at?: never;
          }
      >;
    };
  };
};
interface Response {
  ticketing_instructions: TicketingInstructionResponseArgs[];
  total_count: number;
  total_page: number;
}

const tab: TabType = 'TICKETING';

const getDefaultUseQr = (): UseQrQueryKey => {
  return (utils.getParam('use_qr') || 'all') as UseQrQueryKey;
};

const getDefaultOnlyPremiumSupport = (): boolean => {
  return utils.getParam('only_premium_support') === 'true';
};

const getDefaultTicketingMethod = (): TicketingMethodKey2 => {
  return (utils.getParam('ticketing_method') || 'all') as TicketingMethodKey2;
};

const getDefaultSortKey = (): SortKey => {
  return (utils.getParam('sort_key') || 'organization') as SortKey;
};

const getDefaultSortDirection = (): SortDirection => {
  return (utils.getParam('sort_direction') || 'asc') as SortDirection;
};

const Ticketing = observer(() => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<{ traceId: number; message: string } | null>(null);
  const [ticketingInstcutions, setTicketingInstcutions] = useState<TicketingInstruction[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);

  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [useQr, setUseQr] = useState<UseQrQueryKey>(getDefaultUseQr());
  const [onlyPremiumSupport, setOnlyPremiumSupport] = useState<boolean>(getDefaultOnlyPremiumSupport());
  const [ticketingMethod, setTicketingMethod] = useState<TicketingMethodKey2>(getDefaultTicketingMethod());
  const [sortKey, setSortKey] = useState<SortKey>(getDefaultSortKey());
  const [sortDirection, setSortDirection] = useState<SortDirection>(getDefaultSortDirection());
  const [uploadFileData, setUploadFileData] = useState<UploadFileData>({});

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedTicketingInstruction, setSelectedTicketingInstruction] = useState<TicketingInstruction | null>(
    null
  );

  const fetchItems = useCallback(() => {
    setIsLoading(true);
    setError(null);

    const params = {
      page,
      organization_ids: organizationIds.join(','),
      use_qr: useQr,
      only_premium_support: onlyPremiumSupport,
      ticketing_method: ticketingMethod,
      sort_key: sortKey,
      sort_direction: sortDirection
    };

    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    history.push(`/arrangement/todo_list?tab=${tab}&${query}`);

    Fetcher.get<Response>('/arrangement/ticketings.json', params)
      .then(res => {
        setTicketingInstcutions(
          res.ticketing_instructions.map(
            args => new TicketingInstruction(convertTicketingInstructionResponseToArgs(args))
          )
        );
        setTotalCount(res.total_count);
        setTotalPage(res.total_page);
        if (res.total_page < page) {
          setPage(1);
        }

        // upload_file_data を設定
        const newUploadFileData = res.ticketing_instructions.reduce((acc, instruction) => {
          const traceId = instruction.trace_id;
          if (!acc[traceId]) {
            acc[traceId] = {};
          }
          instruction.transport_elements?.forEach(element => {
            if (!acc[traceId][element.id]) acc[traceId][element.id] = {};

            (element as any)?.transport_element_attach_files?.forEach((file: any) => {
              const travelerId = file.traveler_information_id?.toString() || 'null';
              if (!acc[traceId][element.id][travelerId]) {
                acc[traceId][element.id][travelerId] = [];
              }
              // ファイルデータを追加
              acc[traceId][element.id][travelerId].push({
                id: file.id,
                transport_element_id: element.id,
                traveler_information_id: file.traveler_information_id,
                trip_id: instruction.trip_id,
                upload_file_name: file.upload_file_name,
                content_type: file.content_type,
                file_path: file.file_path,
                created_at: file.created_at,
                updated_at: file.updated_at
              });
            });
          });
          return acc;
        }, {} as UploadFileData);
        setUploadFileData(newUploadFileData);
      })
      .finally(() => setIsLoading(false));
  }, [page, organizationIds, useQr, onlyPremiumSupport, ticketingMethod, sortKey, sortDirection]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleSendTicketing = (ti: TicketingInstruction) => {
    const isQrMethod = ['qr_east', 'qr_west'].includes(ti.ticketingMethod);
    const hasTravelerWithoutAttachFile = ti.travelerInformations?.some(traveler => {
      const travelerIdKey = traveler.id?.toString() || 'null';
      return (
        !uploadFileData[ti.traceId] ||
        Object.values(uploadFileData[ti.traceId]).every(
          filesByTraveler => !filesByTraveler[travelerIdKey] || filesByTraveler[travelerIdKey].length === 0
        )
      );
    });

    if (isQrMethod && hasTravelerWithoutAttachFile) {
      setSelectedTicketingInstruction(ti);
      setIsConfirmModalOpen(true);
      return;
    }

    sendTicketing(ti);
  };

  const sendTicketing = useCallback(
    (ti: TicketingInstruction) => {
      setIsUpdating(true);
      setError(null);

      const params = {
        trace_id: ti.traceId,
        reservation_numbers: ti.reservationNumbers,
        purchase_dates: ti.purchaseDates.map(d => d?.format('YYYY-MM-DD')),
        upload_file_data: uploadFileData[ti.traceId] || {}
      };
      // アップロードされたファイルがあるかを確認
      const hasUploadedFiles =
        !!uploadFileData[ti.traceId] &&
        Object.values(uploadFileData[ti.traceId])
          .flat()
          .some(file => file && typeof file === 'object' && Object.keys(file).length > 0);
      Fetcher.put<Response>('/arrangement/ticketings/proceed.json', params)
        .then(() => {
          if (hasUploadedFiles) {
            Notification.success('アップロードが完了しました');
          }
          fetchItems();
        })
        .catch(e => {
          setError({
            traceId: ti.traceId,
            message:
              e instanceof HTTPError && e.response?.data.errors
                ? e.response.data.errors.join('\n')
                : 'エラーが発生しました'
          });
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [fetchItems, uploadFileData]
  );

  const revertTicketingInstruction = useCallback(
    (ti: TicketingInstruction) => {
      setIsUpdating(true);
      setError(null);
      const params = {
        order_item_id: ti.orderItemId
      };
      Fetcher.put<Response>('/arrangement/ticketing_instructions/revert.json', params)
        .then(() => fetchItems())
        .catch(e => {
          setError({
            traceId: ti.traceId,
            message:
              e instanceof HTTPError && e.response?.data.errors
                ? e.response.data.errors.join('\n')
                : 'エラーが発生しました'
          });
        })
        .finally(() => setIsUpdating(false));
    },
    [fetchItems]
  );

  const isConvertWordToBold = (message: string): boolean => {
    return message.startsWith('座席：') && message.startsWith('座席：指定席') === false;
  };

  const handleUploadFileChange = (
    file: File,
    traceId: string,
    transportElementId: number,
    travelerInformationId: string | null
  ) => {
    if (!file) return;
    TransportElementAttachFile.convertFileToBase64(file)
      .then(base64String => {
        const newFileData = {
          upload_file_name: file.name,
          content_type: file.type,
          traveler_information_id: travelerInformationId,
          upload_file_base64: base64String
        };

        setUploadFileData(prev => {
          const travelerIdKey = travelerInformationId ?? 'null';
          const updated = { ...prev };
          if (!updated[traceId]) {
            updated[traceId] = {};
          }
          if (!updated[traceId][transportElementId]) {
            updated[traceId][transportElementId] = {};
          }
          if (!updated[traceId][transportElementId][travelerIdKey]) {
            updated[traceId][transportElementId][travelerIdKey] = [];
          }
          if (!updated[traceId][transportElementId]) {
            updated[traceId][transportElementId] = {};
          }
          updated[traceId][transportElementId][travelerIdKey].push(newFileData);
          return updated;
        });
      })
      .catch(error => {
        console.error('Failed to convert file to Base64', error);
      });
  };

  const handleDeleteFile = (
    traceId: string,
    transportElementId: number,
    travelerInformationId: string | null,
    index: number
  ) => {
    setUploadFileData(prev => {
      const travelerIdKey = travelerInformationId ?? 'null';
      const updated = { ...prev };

      // traceId,transportElementId,travelerIdKeyが存在する場合のみ処理
      if (updated[traceId]?.[transportElementId]?.[travelerIdKey]?.length > index) {
        updated[traceId][transportElementId][travelerIdKey].splice(index, 1);

        // ファイルがなくなった場合、該当のKey を削除
        if (updated[traceId][transportElementId][travelerIdKey].length === 0) {
          delete updated[traceId][transportElementId][travelerIdKey];
        }
        if (Object.keys(updated[traceId][transportElementId]).length === 0) {
          delete updated[traceId][transportElementId];
        }
        if (Object.keys(updated[traceId]).length === 0) {
          delete updated[traceId];
        }
      }
      return updated;
    });
  };

  return (
    <>
      <SearchArea>
        <SearchBlock>
          <SearchLabel>法人</SearchLabel>
          {renderOrganizationSelector()}
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>QR利用企業</SearchLabel>
          <Select
            value={useQr}
            onChange={e => setUseQr(e.target.value as UseQrQueryKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {UseQrQueryKeys.map(key => (
              <option key={key} value={key}>
                {UseQrQuery[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>発券方法</SearchLabel>
          <Select
            value={ticketingMethod}
            onChange={e => setTicketingMethod(e.target.value as TicketingMethodKey2)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {TicketingMethodKeys2.map(key => (
              <option key={key} value={key}>
                {TicketingMethod2[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <Checkbox checked={onlyPremiumSupport} onChange={e => setOnlyPremiumSupport(e.target.checked)}>
            <b style={{ fontSize: '12px' }}>土日祝オプション企業のみ</b>
          </Checkbox>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>並び順</SearchLabel>
          <select
            value={sortKey}
            onChange={e => setSortKey(e.target.value as SortKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            <option value="organization">法人</option>
            <option value="received_at">依頼日</option>
            <option value="started_at">出発日</option>
          </select>
          <Radio
            checked={sortDirection === 'asc'}
            onChange={() => setSortDirection('asc')}
            style={{ marginRight: '5px' }}
          >
            昇順▲
          </Radio>
          <Radio checked={sortDirection === 'desc'} onChange={() => setSortDirection('desc')}>
            降順▼
          </Radio>
        </SearchBlock>
      </SearchArea>
      <Box>未処理件数: {totalCount}件</Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>出発日</TableCell>
                <TableCell>依頼日</TableCell>
                <TableCell>企業名</TableCell>
                <TableCell>QR利用企業</TableCell>
                <TableCell>土日祝対応</TableCell>
                <TableCell>発券方法</TableCell>
                <TableCell>備考</TableCell>
                <TableCell>チャットメッセージ</TableCell>
                <TableCell>TripID</TableCell>
                <TableCell>TraceID</TableCell>
                <TableCell>最新のログメッセージ</TableCell>
                <TableCell>内容</TableCell>
                <TableCell>予約番号</TableCell>
                <TableCell>仕入日</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketingInstcutions.map(ti => (
                <TableRow key={ti.orderItemId}>
                  <TableCell nowrap>{ti.startDate?.format('YYYY-MM-DD') || ''}</TableCell>
                  <TableCell nowrap>{ti.receivedAt.format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell>{ti.organizationName}</TableCell>
                  <TableCell>{ti.useQr ? '◯' : ''}</TableCell>
                  <TableCell>{ti.premiumSupport ? '◯' : ''}</TableCell>
                  <TableCell>{TicketingMethod[ti.ticketingMethod]}</TableCell>
                  <TableCell>{ti.ticketingNote}</TableCell>
                  <TableCell>
                    {ti.latestMessage?.split('\n').map((line, index) => (
                      <Box key={index}>{line}</Box>
                    ))}
                  </TableCell>
                  <TableCell nowrap>
                    <Link
                      href={`/arrangement/virtual_counter?trip_id=${ti.tripId}`}
                      target="_blank"
                      rel="noopener noreffer"
                    >
                      {ti.tripId}
                    </Link>
                  </TableCell>
                  <TableCell nowrap>{ti.traceId}</TableCell>
                  <TableCell>{ti.latestLog}</TableCell>
                  <TableCell>
                    {ti.content?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {isConvertWordToBold(line) ? <strong>{line}</strong> : <>{line}</>}
                        {index !== (ti.content?.split('\n').length || 0) - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <ReservationNumberCell>
                    <>
                      {ti.reservationNumbers.map((reservationNumber, i) => (
                        <Box key={i}>
                          <Input
                            value={reservationNumber || ''}
                            onChange={e => {
                              ti.reservationNumbers[i] = e.target.value;
                            }}
                          />
                        </Box>
                      ))}
                      {['qr_west', 'qr_east'].includes(ti.ticketingMethod) &&
                        ti?.transportElements?.map((element, j) => (
                          <AttachFileSection key={j}>
                            <TransportElementAttachFileForm
                              attachFiles={element.transportElementAttachFiles}
                              travelerInformations={ti?.travelerInformations || []}
                              transportElement={element}
                              onChange={(file, transportElementId, travelerInformationId) =>
                                handleUploadFileChange(file, String(ti.traceId), element.id, travelerInformationId)
                              }
                              onDelete={(transportElementId, travelerInformationId, index) =>
                                handleDeleteFile(String(ti.traceId), element.id, travelerInformationId, index)
                              }
                            />
                          </AttachFileSection>
                        ))}
                    </>
                  </ReservationNumberCell>
                  <TableCell>
                    {ti.purchaseDates.map((date, i) => (
                      <DatetimePicker
                        key={i}
                        value={date || undefined}
                        onChange={d => {
                          ti.purchaseDates[i] = d;
                        }}
                        showPast
                        border
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Button loading={isUpdating} onClick={() => handleSendTicketing(ti)}>
                        発券完了
                      </Button>
                    </Box>
                    <Box marginTop="10px">
                      <Button loading={isUpdating} color="sub" onClick={() => revertTicketingInstruction(ti)}>
                        発券指示に戻す
                      </Button>
                    </Box>
                    {error?.traceId === ti.traceId && (
                      <Box marginTop="10px" color="red">
                        {error.message}
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Modal open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)} size="medium">
            <ModalHeader>発券確認</ModalHeader>
            <ModalBody>
              <Confirm
                onConfirmed={() => {
                  if (selectedTicketingInstruction) {
                    sendTicketing(selectedTicketingInstruction);
                  }
                  setIsConfirmModalOpen(false);
                  setSelectedTicketingInstruction(null);
                }}
                onAborted={() => {
                  setIsConfirmModalOpen(false);
                  setSelectedTicketingInstruction(null);
                }}
                message="ファイルが添付されていませんが、このまま発券完了しますか？"
              />
            </ModalBody>
          </Modal>
          <TablePagination
            count={totalPage}
            page={page}
            onChange={(_: unknown, newPage: number) => setPage(newPage)}
          />
        </>
      )}
    </>
  );
});

const AttachFileSection = styled.div`
  padding: 5px;
`;

const ReservationNumberCell = styled(TableCell)`
  min-width: 250px;
  word-wrap: break-word;
  white-space: normal;
`;

export default Ticketing;
