import _ from 'lodash';
import type MarginType from '@this/domain/organization/margin_type2';
import { Fetcher } from '@this/src/util';
import moment from '../../lib/moment';
import type { HotelJson, SupplierDomain } from '../select_repository';
import type HotelStayPlan from './hotel_stay_plan';

export type Args = Partial<HotelJson> & {
  includeTax?: boolean;
  showFee: boolean;
  marginType?: MarginType;
  loading?: boolean;
};

export type HotelBedType = {
  description: string;
  id: number;
};

class Hotel {
  raw: Args;

  // HotelJson
  readonly id: string | null | undefined;

  readonly hotelId: number | null | undefined;

  readonly name: string | null | undefined;

  public price: number | null | undefined;

  public average_price: number | null | undefined;

  readonly mandatory_tax: number | null | undefined;

  public readonly image: string | null | undefined;

  public readonly images: string[] | null | undefined;

  readonly latitude: number | null | undefined;

  readonly longitude: number | null | undefined;

  public readonly distance: number | null | undefined;

  public readonly station_distance: number | null | undefined;

  public bed_types: HotelBedType[] | null | undefined;

  public readonly breakfast: string | null | undefined;

  public readonly stay_days: number | null | undefined;

  public sold_out: boolean | null | undefined;

  public rating: number | null | undefined;

  // HotelJson
  public readonly address: string | null | undefined;

  private readonly price_detail: { nightly_rate: string; tax_and_service_fee: number } | null | undefined;

  public readonly price_detail_text: string | null | undefined;

  public average_price_with_tax: number | null | undefined;

  private readonly checkin: string | null | undefined;

  private readonly checkout: string | null | undefined;

  public readonly note: string | null | undefined;

  public readonly checkin_date: string | null | undefined;

  public readonly checkout_date: string | null | undefined;

  public readonly roomnum: string | null | undefined;

  private readonly raw_hotel: any | undefined;

  private readonly raw_detail: any | undefined;

  private readonly raw_room: any | undefined;

  public readonly refund: string | null | undefined;

  public readonly refundable: null | undefined;

  public room_type: string | null | undefined;

  public room_smoke: boolean | null | undefined;

  private readonly checkin_instructions: string | null | undefined;

  private readonly special_checkin_instrcutions: null | undefined;

  private readonly cancel_policy: null | undefined;

  private readonly property_amenities: null | undefined;

  private readonly room_amenities: null | undefined;

  public readonly type:
    | 'r'
    | 'e'
    | 'eps_rapid'
    | 'net_rate_eps_rapid'
    | 'mynavi'
    | 'new_rakuten'
    | 'agoda'
    | undefined;

  public readonly operator_class_name: string | null | undefined;

  public readonly detail_path: string | null | undefined;

  public readonly package_type: 'FP' | 'RP' | 'JR' | null | undefined;

  public readonly package_base_price: number | null | undefined;

  public too_late?: boolean;

  public tel?: string;

  private marginType: MarginType | undefined;

  public optimal?: boolean;

  public readonly supplierDomain?: SupplierDomain;

  public readonly parkingInformation: string | undefined;

  public isOverLimit: boolean | null = null;

  public plan_name: string | null | undefined;

  public readonly plan_detail: string | null | undefined;

  public selected_stay_plan_id: string;

  public readonly stay_plans: Array<HotelStayPlan>;

  // from this class
  includeTax: boolean | undefined;

  orderedIndex: number | undefined;

  imageIndex: number;

  isSP: boolean;

  hovered: boolean;

  checkinDate: moment.Moment | undefined;

  checkoutDate: moment.Moment | undefined;

  walkminute: string | undefined;

  showFee: boolean;

  // ホテル個別取得時のもの
  loading: boolean;

  constructor(args_?: Args | null, index?: number) {
    const args: Args = args_ != null ? args_ : ({} as Args);

    // HotelJson
    this.id = args.id;
    this.hotelId = args.hotel_id;
    this.name = args.name;
    this.price = args.price;
    this.price_detail = args.price_detail;
    this.price_detail_text = args.price_detail_text;
    this.average_price = args.average_price;
    this.average_price_with_tax = args.average_price_with_tax;
    this.mandatory_tax = args.mandatory_tax;
    this.image = args.image;
    this.images = args.images;
    this.checkin = args.checkin;
    this.checkout = args.checkout;
    this.note = args.note || '';
    this.checkin_date = args.checkin_date;
    this.checkout_date = args.checkout_date;
    this.walkminute = args.walkminute || undefined;
    this.latitude = args.latitude;
    this.longitude = args.longitude;
    this.distance = args.distance;
    this.station_distance = args.station_distance;
    this.bed_types = args.bed_types;
    this.breakfast = args.breakfast;
    this.roomnum = args.roomnum;
    this.stay_days = args.stay_days;
    this.raw_hotel = args.raw_hotel;
    this.raw_detail = args.raw_detail;
    this.raw_room = args.raw_room;
    this.refund = args.refund;
    this.refundable = args.refundable;
    this.sold_out = args.sold_out;
    this.rating = args.rating;
    this.room_type = args.room_type;
    this.room_smoke = args.room_smoke;
    this.checkin_instructions = args.checkin_instructions;
    this.special_checkin_instrcutions = args.special_checkin_instrcutions;
    this.cancel_policy = args.cancel_policy;
    this.property_amenities = args.property_amenities;
    this.room_amenities = args.room_amenities;
    this.type = args.type;
    this.operator_class_name = args.operator_class_name;
    this.detail_path = args.detail_path;
    this.package_type = args.package_type;
    this.package_base_price = args.package_base_price;
    this.too_late = args.too_late;
    this.tel = args.tel;
    this.optimal = args.optimal;
    this.plan_name = args.plan_name;
    this.plan_detail = args.plan_detail;
    this.selected_stay_plan_id = args.selected_stay_plan_id || '';
    this.stay_plans = args.stay_plans ? args.stay_plans : [];

    this.raw = args;
    this.includeTax = args.includeTax;
    this.orderedIndex = index;
    this.imageIndex = 0;
    this.isSP = false;
    this.hovered = false;
    const checkin = args.checkin_date && moment(args.checkin_date);
    if (checkin && checkin.isValid()) this.checkinDate = checkin;
    const checkout = args.checkout_date && moment(args.checkout_date);
    if (checkout && checkout.isValid()) this.checkoutDate = checkout;
    this.showFee = args.showFee;
    this.marginType = args.marginType;
    this.supplierDomain = args.supplier_domain;
    this.parkingInformation = args.parking_information || '';
    this.loading = args.loading || false;
  }

  fetchDistance(location: { lat: number; lng: number }) {
    if (this.latitude && this.longitude) {
      this.walkminute = utils.walkMinutesText(
        utils.calcDistance(location.lat, location.lng, this.latitude, this.longitude)
      );
    }
  }

  //  only for old data that doesn't have 'address' property
  addressText() {
    if (this.address) return this.address;
    if (!this.raw_hotel) return null;
    let address = this.raw_hotel.address1;
    if (this.raw_hotel.address2) address += ` ${this.raw_hotel.address2}`;
    address += `, ${this.raw_hotel.city}`;
    return address;
  }

  totalPrice() {
    let price = this.price || 0;
    if (this.showFee) {
      price += this.marginAmount();
    }
    return price;
  }

  marginAmount(): number {
    if (this.marginType) {
      const roomNum = parseInt(this.roomnum || '1', 10);
      return this.marginType.calcMarginAmount(this.price || 0, roomNum);
    }
    return 0;
  }

  marginAmountOrderItem(price: number): number {
    if (this.marginType) {
      const roomNum = parseInt(this.roomnum || '1', 10);
      return this.marginType.calcMarginAmount(price || 0, roomNum);
    }
    return 0;
  }

  priceText() {
    return utils.formatPrice(this.price);
  }

  averagePriceText() {
    if (this.average_price) {
      return utils.formatPrice(this.average_price);
    }
    return null;
  }

  getAveragePrice() {
    return this.includeTax ? this.average_price_with_tax : this.average_price;
  }

  getAveragePriceWithTax() {
    return this.average_price_with_tax;
  }

  priceDetailHash() {
    const h: { [key: string]: string } = {};
    h[`└ 宿泊料(${this.stay_days}泊${this.roomnum}室)`] = this.nightlyPriceText();
    if (this.price_detail && this.price_detail.tax_and_service_fee && this.price_detail.tax_and_service_fee > 0) {
      h['└ 税・サービス料'] = this.taxText();
    }
    const marginAmount = this.marginAmount();
    if (this.showFee && this.marginType && marginAmount > 0) {
      h['└ 手配手数料'] = this.marginType.describe(this.price || 0, parseInt(this.roomnum || '1', 10));
    }
    return h;
  }

  priceDetailHashOrderItem(price: number, inclusive_tax: number) {
    const h: { [key: string]: string } = {};
    h[`└ 宿泊料(${this.stay_days}泊${this.roomnum}室)`] = utils.formatPrice(price - inclusive_tax);
    if (inclusive_tax > 0) {
      h['└ 税・サービス料'] = utils.formatPrice(inclusive_tax);
    }
    const marginAmount = this.marginAmountOrderItem(price);
    if (this.showFee && this.marginType && marginAmount > 0) {
      h['└ 手配手数料'] = this.marginType.describe(price || 0, parseInt(this.roomnum || '1', 10));
    }
    return h;
  }

  priceDetailTextOrderItem(price: number, inclusive_tax: number) {
    return `└ 宿泊料(${this.stay_days}泊${this.roomnum}室)： ${utils.formatPrice(
      price - inclusive_tax
    )}円\n└ 税・サービス料： ${utils.formatPrice(inclusive_tax)}円`;
  }

  priceDetailText() {
    const t = `ホテル：${utils.formatPrice(this.totalPrice())}\n`;
    const detail = _.map(this.priceDetailHash(), (v, k) => `${k}: ${v}`).join('\n');
    return t + detail;
  }

  priceDetailTextOrderUItem(total_price: number, price: number, inclusive_tax: number) {
    const t = `ホテル：${utils.formatPrice(total_price)}\n`;
    const detail = _.map(this.priceDetailHashOrderItem(price, inclusive_tax), (v, k) => `${k}: ${v}`).join('\n');
    return t + detail;
  }

  nightlyPriceText() {
    return utils.formatPrice(Number(this.price_detail && this.price_detail.nightly_rate));
  }

  taxText() {
    return utils.formatPrice(this.price_detail && this.price_detail.tax_and_service_fee);
  }

  averagePriceDetailHash() {
    const h: { [key: string]: string | null } = {};
    h['宿泊料(1泊1室)'] = this.averagePriceText();
    if (this.price_detail && this.price_detail.tax_and_service_fee && this.price_detail.tax_and_service_fee > 0) {
      h['税・サービス料'] = utils.formatPrice((this.average_price_with_tax || 0) - (this.average_price || 0));
    }
    return h;
  }

  averagePriceDetailArray() {
    const arr = [];
    if (this.averagePriceText()) {
      arr.push({ title: '宿泊料(1泊1室):', price: this.averagePriceText() });
    }
    if (this.price_detail && this.price_detail.tax_and_service_fee && this.price_detail.tax_and_service_fee > 0) {
      arr.push({
        title: '税・サービス料:',
        price: utils.formatPrice((this.average_price_with_tax || 0) - (this.average_price || 0))
      });
    }
    return arr;
  }

  averagePriceTDetailText() {
    const t = `ホテル：${utils.formatPrice(this.average_price_with_tax)}\n`;
    const detail = _.map(this.averagePriceDetailHash(), (v, k) => `${k}: ${v}`).join('\n');
    return t + detail;
  }

  walkMinuteText() {
    if (this.walkminute) {
      return `徒歩${this.walkminute}`;
    }
    return '(計算中...)';
  }

  stationDistanceText() {
    if (this.station_distance) {
      return `徒歩${Math.floor(this.station_distance / 80)}分`;
    }
    return '-';
  }

  distanceText() {
    if (this.distance) {
      return `${Math.round(this.distance)}m`;
    }
    return '-';
  }

  checkinText() {
    return this.checkin || '-';
  }

  checkoutText() {
    return this.checkout || '-';
  }

  propertyAmenities() {
    const raw =
      this.property_amenities || utils.dig(this.raw_detail, 'PropertyAmenities', 'PropertyAmenity') || [];
    return _.map(raw, a => a.item || a.amenity || a);
  }

  roomAmenities() {
    let rooms;
    if (utils.dig(this.raw_detail, 'RoomTypes', '@size') === '1') {
      rooms = [utils.dig(this.raw_detail, 'RoomTypes', 'RoomType')];
    } else {
      rooms = utils.dig(this.raw_detail, 'RoomTypes', 'RoomType');
    }
    const room = _.find(
      rooms,
      room => room['@roomCode'].toString() === utils.dig(this.raw_room, 'roomTypeCode').toString()
    );
    let amenities = utils.dig(room, 'roomAmenities', 'RoomAmenity');
    amenities = this.room_amenities || amenities;
    return _.map(amenities, a => a.item || a.amenity || a);
  }

  cancelPolicy() {
    return this.cancel_policy || utils.dig(this.raw_room, 'RateInfos', 'RateInfo', 'cancellationPolicy');
  }

  checkinInstructions() {
    const ci = this.checkin_instructions;
    if (ci) return ci.replace(/<br \/>/g, '');
    return '';
  }

  specialCheckinInstructions() {
    return this.special_checkin_instrcutions;
  }

  currentImage() {
    return this.images && this.images[this.imageIndex];
  }

  selectImage(i: number) {
    this.imageIndex = i;
    app.render();
  }

  setIncludeTax(value: boolean) {
    this.includeTax = value;
  }

  handleTouched = () => {
    this.isSP = true;
  };

  setHovered = (value: boolean) => () => {
    if (!this.isSP) {
      this.hovered = value;
      app.render();
    }
  };

  setSoldOut(value: true) {
    this.sold_out = value;
    this.hovered = false;
    app.render();
  }

  setRating(rate: number) {
    this.rating = rate;
    this.hovered = false;
    app.render();
  }

  setTooLate(bool: boolean) {
    this.too_late = bool;
    this.hovered = false;
    app.render();
  }

  setIsOverLimit(bool: boolean) {
    this.isOverLimit = bool;
    app.render();
  }

  checkinDateStr() {
    return this.checkinDate && this.checkinDate.format('YYYY/MM/DD');
  }

  checkoutDateStr() {
    return this.checkoutDate && this.checkoutDate.format('YYYY/MM/DD');
  }

  startDateTime() {
    // /trips/:id/edit での日時順並び替えの際に宿泊施設は同日の交通機関よりも後に並んで欲しい
    return this.checkin_date && moment(`${this.checkin_date} 23:59`);
  }

  stayDays() {
    if (!this.checkinDate || !this.checkoutDate) return undefined;

    return this.checkoutDate.diff(this.checkinDate, 'days');
  }

  days() {
    const stayDays = this.stayDays();
    return stayDays && stayDays + 1;
  }

  toRaw() {
    return this.raw;
  }

  rated() {
    return !!this.rating;
  }

  isForeign() {
    return !!this.marginType && this.marginType.category === 'foreign_hotel';
  }

  roomBasicInfoExists() {
    return utils.dig(this.raw_room, 'roomInfo', 0, 'roomBasicInfo');
  }

  rakutenRoomName() {
    return utils.dig(this.raw_room, 'roomInfo', 0, 'roomBasicInfo', 'roomName');
  }

  isPackage() {
    return !_.isNil(this.package_type);
  }

  isExpedia() {
    return ['eps_rapid', 'net_rate_eps_rapid'].includes(this.type || '');
  }

  planDisplayType(): 'plain' | 'tooltip' {
    // プラン名が無い場合は「設定なし」で固定のため、プレーン表示
    if (_.isEmpty(this.plan_name)) {
      return 'plain';
    }

    // agoda, eps_rapid, net_rate_eps_rapid はプラン名と部屋タイプが同じため、プラン名を表示しない
    // => 部屋タイプの方を同名の場合表示しない対応をしたため、こちらでの制御はコメントアウト
    // if (['agoda', 'eps_rapid', 'net_rate_eps_rapid'].includes(this.type || '')) {
    //   return 'plain';
    // }

    // プラン名がある場合はツールチップ表示
    return 'tooltip';
  }

  showCancelStatus() {
    let result = false;
    if (
      (this.type === 'new_rakuten' || this.type === 'agoda') &&
      this.cancelPolicy().indexOf('キャンセル不可') === -1
    ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  setHotelLoading(loading: boolean) {
    this.loading = loading;
    app.render();
  }

  async fetchPlanDetail(stayPlan: HotelStayPlan): Promise<string | null> {
    switch (this.operator_class_name) {
      case 'RakutenOperator':
      case 'RakutenStaticfileOperator': {
        const planDetail = await this.fetchRakutenPlanDetail(stayPlan);
        return planDetail;
      }
      case 'AgodaOperator':
        return this.fetchAgodaPlanDetail(stayPlan);
      default:
        return null;
    }
  }

  async fetchRakutenPlanDetail(stayPlan: HotelStayPlan): Promise<string | null> {
    const params = {
      hotel_id: this.hotelId,
      plan_id: stayPlan.id?.split('_')[1]
    };

    const planDetail = await Fetcher.get<{ plan_detail: string | null }>(
      '/hotels/rakuten_plan_detail',
      params
    ).then(result => {
      return result.plan_detail;
    });

    return planDetail;
  }

  fetchAgodaPlanDetail(stayPlan: HotelStayPlan): string | null {
    return stayPlan.plan_detail || null;
  }
}

export default Hotel;
