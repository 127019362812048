import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@this/src/components/shared/ui/data_displays/table';
import moment from 'moment';
import { Fetcher } from '../../../../util/fetcher/fetcher.util';

const messageContainerStyle = {
  textAlign: 'center' as const,
  padding: '20px'
};

const messages = {
  loading: 'ログを読み込み中...',
  noLogs: {
    main: 'このTrace IDに関連するAI Operatorの動作ログはまだ記録されていません。',
    sub: 'AI Operatorで予約操作を行うと、自動的にログが記録されます。'
  }
} as const;

interface ExtensionLog {
  page_url: string;
  page_title: string;
  visited_at: string;
}

interface ExtensionLogsProps {
  traceId: string;
}

interface ExtensionLogsResponse {
  logs: ExtensionLog[];
}

const fetchExtensionLogs = async (traceId: string): Promise<ExtensionLogsResponse> => {
  return Fetcher.get(`/arrangement/order_item/${traceId}/extension_logs`);
};

export const ExtensionLogs: React.FC<ExtensionLogsProps> = ({ traceId }) => {
  const [logs, setLogs] = useState<ExtensionLog[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadLogs = async () => {
      try {
        const response = await fetchExtensionLogs(traceId);
        setLogs(response.logs);
      } catch (error) {
        console.error('Failed to load extension logs:', error);
      } finally {
        setLoading(false);
      }
    };

    loadLogs();
  }, [traceId]);

  const isValidUrl = (url: string): boolean => {
    try {
      const parsed = new URL(url);
      return parsed.protocol === 'http:' || parsed.protocol === 'https:';
    } catch {
      return false;
    }
  };

  const sanitizeUrl = (url: string): string => {
    if (!isValidUrl(url)) {
      return '#';
    }
    return url;
  };

  const truncateUrl = (url: string, maxLength = 100): string => {
    return url.length > maxLength ? `${url.substring(0, maxLength)}...` : url;
  };

  const isInvalidUrl = (url: string): boolean => {
    return url === '#';
  };

  const columns = [
    {
      title: '訪問日時',
      dataIndex: 'visited_at',
      key: 'visited_at',
      render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'ページタイトル',
      dataIndex: 'page_title',
      key: 'page_title'
    },
    {
      title: 'URL',
      dataIndex: 'page_url',
      key: 'page_url',
      render: (text: string) => {
        const safeUrl = sanitizeUrl(text);
        const displayUrl = truncateUrl(text);
        return isInvalidUrl(safeUrl) ? (
          <span style={{ color: 'red' }}>無効なURL</span>
        ) : (
          <a href={safeUrl} target="_blank" rel="noopener noreferrer" title={text}>
            {displayUrl}
          </a>
        );
      }
    }
  ];

  if (loading) {
    return (
      <div style={messageContainerStyle}>
        <p>{messages.loading}</p>
      </div>
    );
  }

  if (!logs || logs.length === 0) {
    return (
      <div style={messageContainerStyle}>
        <p>{messages.noLogs.main}</p>
        <p>{messages.noLogs.sub}</p>
      </div>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell key={column.key}>{column.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map(log => (
          <TableRow key={`${log.visited_at}-${log.page_url}`}>
            <TableCell>{moment(log.visited_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
            <TableCell>{log.page_title}</TableCell>
            <TableCell>
              <a href={log.page_url} target="_blank" rel="noopener noreferrer">
                {log.page_url}
              </a>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
