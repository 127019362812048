import React, { useState, useEffect, useRef } from 'react';
import qs from 'qs';
import moment from 'moment';
import type { RouteComponentProps } from 'react-router-dom';
import { Fetcher } from '@this/src/util/fetcher/fetcher.util';
import { dig } from '@this/src/util/object/object.util';
import { getParams } from '@this/src/util/fetcher/fetcher.util';
import { ArrangementReportsTemplate } from './arrangement_reports.template';

type Props = RouteComponentProps;

// Import the types from the filter component
type ArrangementReportsFilterGroupBy = 'day' | 'week' | 'month';

type ArrangementReportsFilterData = {
  groupBy: ArrangementReportsFilterGroupBy;
  fromDate: Date;
  toDate: Date;
};

// Match the ReportTime type from arrangement_report_table.tsx
type ReportTime = {
  range: string;
  str: string;
  value: string;
};

type State = {
  initialFilterData: ArrangementReportsFilterData | null;
  loading: boolean;
  items: any[];
  times: ReportTime[];
};

const dateFormat = 'YYYY-MM-DD';

const ArrangementReports: React.FC<Props> = props => {
  const [state, setState] = useState<State>({
    initialFilterData: null,
    loading: false,
    items: [],
    times: []
  });

  const prevSearch = useRef(props.location.search);

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    // クエリパラメータが変更されたら再検索する
    if (props.location.search !== prevSearch.current) {
      prevSearch.current = props.location.search;
      fetchItems();
    }
  }, [props.location.search]);

  const validateWeekDay = (date: moment.Moment, start: boolean): moment.Moment => {
    if (start) {
      if (date.isSame(date.clone().startOf('month'), 'day')) {
        return date;
      }

      const d = date.clone().startOf('isoWeek');
      return d.month() < date.month() ? date.startOf('month') : d;
    }

    if (date.isSame(date.clone().endOf('month'), 'day')) {
      return date;
    }

    const d = date.clone().endOf('isoWeek');
    return date.month() < d.month() ? date.endOf('month') : d;
  };

  const searchParams = (searchMode?: string) => {
    const params = getParams();
    const fromStr = dig(params, 'from');
    const toStr = dig(params, 'to');
    const mode = searchMode || dig(params, 'mode') || 'day';
    let from: moment.Moment | null = null;
    let to: moment.Moment | null = null;

    switch (mode) {
      case 'week':
        if (fromStr) {
          from = validateWeekDay(moment(fromStr), true);
        } else {
          from = moment().startOf('month');
        }

        if (toStr) {
          to = validateWeekDay(moment(toStr), false);
        } else {
          to = from.clone().add(6, 'd');
        }
        break;

      case 'month':
        if (fromStr) {
          from = moment(fromStr).startOf('month');
        } else {
          from = moment().subtract(1, 'month').startOf('month');
        }

        if (toStr) {
          to = moment(toStr).endOf('month');
        } else {
          to = from.clone().endOf('month');
        }
        break;

      default:
        from = fromStr ? moment(fromStr) : moment().startOf('month');
        to = toStr ? moment(toStr) : moment().endOf('month');
    }

    return {
      mode,
      fromDate: from,
      toDate: to
    };
  };

  const fetchItems = () => {
    const params = searchParams();
    const fromDate = params.fromDate;
    const toDate = params.toDate;
    const mode = params.mode;

    setState(prevState => ({
      ...prevState,
      loading: true,
      initialFilterData: {
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate(),
        groupBy: mode as 'day' | 'week' | 'month'
      }
    }));

    Fetcher.get('/admin/arrangement_reports', {
      from: fromDate.format(dateFormat),
      to: toDate.format(dateFormat),
      mode
    })
      .then((result: any) => {
        setState(prevState => ({
          ...prevState,
          items: result.items || [],
          loading: false,
          times: (result.times || []).map((t: any) => ({
            value: t.value,
            str: t.str,
            range: t.range || ''
          }))
        }));
      })
      .catch((error: any) => {
        console.error('Failed to fetch arrangement reports:', error);
        setState(prevState => ({
          ...prevState,
          loading: false,
          items: [],
          times: []
        }));
      });
  };

  const handleFilterSubmit = (data: ArrangementReportsFilterData) => {
    const from = moment(data.fromDate);
    const to = moment(data.toDate);

    props.history.push({
      search: qs.stringify({
        from: from.format(dateFormat),
        to: to.format(dateFormat),
        mode: data.groupBy
      })
    });
  };

  try {
    // If initialFilterData is null, we're still loading, so just show loading state
    if (!state.initialFilterData) {
      return (
        <ArrangementReportsTemplate
          loading
          initialFilterData={{
            fromDate: new Date(),
            toDate: new Date(),
            groupBy: 'day'
          }}
          reportItems={[]}
          reportTimes={[]}
          onFilterSubmit={handleFilterSubmit}
        />
      );
    }

    return (
      <ArrangementReportsTemplate
        loading={state.loading}
        initialFilterData={state.initialFilterData}
        reportItems={state.items}
        reportTimes={state.times}
        onFilterSubmit={handleFilterSubmit}
      />
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default ArrangementReports;
