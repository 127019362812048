import moment from 'moment';

import User from '../user/user';
import BulkActionProject from './bulk_action_project';
import BulkActionDepartment from './bulk_action_department';
import BulkActionApprover from './bulk_action_approver';
import BulkActionProjectApprover from './bulk_action_project_approver';
import BulkActionUser from './bulk_action_user';
import BulkActionAccountType from './bulk_action_account_type';
import BulkActionExpensesType from './bulk_action_expenses_type';
import BulkActionExicCard from './bulk_action_exic_card';
import BulkActionAdminDepartment from './bulk_action_admin_department';
import BulkActionAdminUser from './bulk_action_admin_user';
import BulkActionAdminExicCard from './bulk_action_admin_exic_card';

const { dig } = require('../../utils');

type ActionType =
  | 'create'
  | 'update'
  | 'create_projects'
  | 'upsert_departments'
  | 'update_approvers'
  | 'update_project_approvers'
  | 'upsert_users'
  | 'delete_users'
  | 'upsert_account_types'
  | 'upsert_expenses_types'
  | 'upsert_exic_cards'
  | 'upsert_admin_departments'
  | 'upsert_admin_users'
  | 'upsert_admin_exic_cards';

class BulkInvitation {
  id: number;

  filename: string;

  status: 'enqueued' | 'running' | 'success' | 'fail';

  kickedByUser: User;

  actionUsers: BulkActionUser[];

  actionProjects: BulkActionProject[];

  actionDepartments: BulkActionDepartment[];

  actionApprovers: BulkActionApprover[];

  actionProjectApprovers: BulkActionProjectApprover[];

  actionExpensesAccountTypes: BulkActionAccountType[];

  actionExpensesTypes: BulkActionExpensesType[];

  actionExicCards: BulkActionExicCard[];

  actionAdminDepartments: BulkActionAdminDepartment[];

  actionAdminUsers: BulkActionAdminUser[];

  actionAdminExicCards: BulkActionAdminExicCard[];

  actionTotal: number;

  actionType: ActionType;

  finishedCount: number;

  actionsStatusCount: { success: number; fail: number; skip: number } | null;

  createdAt: string;

  updatedAt: string;

  constructor(raw: any) {
    this.id = dig(raw, 'id');
    this.filename = dig(raw, 'filename');
    this.status = dig(raw, 'status');
    this.kickedByUser = new User(dig(raw, 'kicked_by_user'));

    const actionUsers = (dig(raw, 'action_users') || []).map(
      (u: any) => new BulkActionUser(u, dig(raw, 'action_type'))
    );
    this.actionUsers = actionUsers || [];

    const actionProjects = (dig(raw, 'action_projects') || []).map((p: any) => new BulkActionProject(p));
    this.actionProjects = actionProjects || [];

    this.actionDepartments = (dig(raw, 'action_departments') || []).map((d: any) => new BulkActionDepartment(d));
    this.actionApprovers = (dig(raw, 'action_approvers') || []).map((d: any) => new BulkActionApprover(d));
    this.actionProjectApprovers = (dig(raw, 'action_project_approvers') || []).map(
      (d: any) => new BulkActionProjectApprover(d)
    );
    this.actionExpensesAccountTypes = (dig(raw, 'action_account_types') || []).map(
      (d: any) => new BulkActionAccountType(d)
    );
    this.actionExpensesTypes = (dig(raw, 'action_expenses_types') || []).map(
      (d: any) => new BulkActionExpensesType(d)
    );
    this.actionExicCards = (dig(raw, 'action_exic_cards') || []).map((d: any) => new BulkActionExicCard(d));
    this.actionAdminDepartments = (dig(raw, 'action_admin_departments') || []).map(
      (d: any) => new BulkActionAdminDepartment(d)
    );
    this.actionAdminUsers = (dig(raw, 'action_admin_users') || []).map((d: any) => new BulkActionAdminUser(d));
    this.actionAdminExicCards = (dig(raw, 'action_admin_exic_cards') || []).map(
      (d: any) => new BulkActionAdminExicCard(d)
    );

    this.actionTotal = dig(raw, 'action_total');
    this.actionType = dig(raw, 'action_type');
    this.finishedCount = dig(raw, 'finished_count');
    this.actionsStatusCount = dig(raw, 'actions_status_count');
    this.createdAt = moment(dig(raw, 'created_at')).format('YYYY-MM-DD HH:mm:ss');
    this.updatedAt = moment(dig(raw, 'updated_at')).format('YYYY-MM-DD HH:mm:ss');
  }

  statusMessage(): string {
    switch (this.status) {
      case 'enqueued':
        return '未着手';
      case 'running':
        return this.runningMessage();
      default:
        return this.completeMessage();
    }
  }

  runningMessage(): string {
    return `実行中（${this.finishedCount}/${this.actionTotal}）`;
  }

  completeMessage(): string {
    let message = '完了';
    if (this.actionsStatusCount) {
      const success = this.actionsStatusCount.success;
      const fail = this.actionsStatusCount.fail;
      const skip = this.actionsStatusCount.skip;
      message += `（成功：${success}件、失敗：${fail}件、スキップ${skip}件）`;
    }
    return message;
  }

  isEnqueued(): boolean {
    return this.status === 'enqueued';
  }

  isRunning(): boolean {
    return this.status === 'running';
  }

  isSuccess(): boolean {
    return this.status === 'success';
  }

  isFail(): boolean {
    return this.status === 'fail';
  }

  isCreateAction(): boolean {
    return this.actionType === 'create';
  }

  isUpdateAction(): boolean {
    return this.actionType === 'update';
  }

  isCreateProjectsAction(): boolean {
    return this.actionType === 'create_projects';
  }

  isUpsertDepartments(): boolean {
    return this.actionType === 'upsert_departments';
  }

  isUpdateApprovers(): boolean {
    return this.actionType === 'update_approvers';
  }

  isUpdateProjectApprovers(): boolean {
    return this.actionType === 'update_project_approvers';
  }

  isUpsertUsers(): boolean {
    return this.actionType === 'upsert_users';
  }

  isDeleteUsers(): boolean {
    return this.actionType === 'delete_users';
  }

  isUpsertAccountTypes(): boolean {
    return this.actionType === 'upsert_account_types';
  }

  isUpsertExpensesTypes(): boolean {
    return this.actionType === 'upsert_expenses_types';
  }

  isUpsertExicCards(): boolean {
    return this.actionType === 'upsert_exic_cards';
  }

  isUpsertAdminDepartments(): boolean {
    return this.actionType === 'upsert_admin_departments';
  }

  isUpsertAdminUsers(): boolean {
    return this.actionType === 'upsert_admin_users';
  }

  isUpsertAdminExicCards(): boolean {
    return this.actionType === 'upsert_admin_exic_cards';
  }
}

export default BulkInvitation;
