export interface TransportElementAttachFileArgs {
  id: number;
  tripId: number;
  uploadFileName: string;
  contentType: string;
  filePath: string;
  travelerInformationId: string | null;
}

export default class TransportElementAttachFile {
  id: number;

  tripId: number;

  uploadFileName: string;

  contentType: string;

  filePath: string;

  travelerInformationId: string | null;

  constructor(args: TransportElementAttachFileArgs) {
    this.id = args.id;
    this.tripId = args.tripId;
    this.uploadFileName = args.uploadFileName;
    this.contentType = args.contentType;
    this.filePath = args.filePath;
    this.travelerInformationId = args.travelerInformationId;
  }

  updateParams() {
    return {
      trip_id: this.tripId,
      traveler_information_id: this.travelerInformationId,
      upload_file_name: this.uploadFileName,
      content_type: this.contentType,
      file_path: this.filePath
    };
  }

  static convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64String = fileReader.result?.toString().replace(/^data:(.*);base64,/, '') || '';
        resolve(base64String);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  }
}
