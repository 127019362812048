export type TravelerCostArgs = {
  address: string;
  bill_amount: number;
  canceled_at: string;
  charging_department_code: number;
  charging_department_name: number;
  charging_department_shareName: number;
  department_code: string;
  department_name: string;
  employee_number: string;
  end_at: string;
  fee: number;
  hotel_price_limit_diff: number;
  internal_number: string;
  minus_amount: number;
  ordered_at: string;
  payment_type: string;
  private_use_desc: string;
  project_code: string;
  project_name: string;
  project_share_name: string;
  start_at: string;
  stay_days: number;
  summary: string;
  ticket_destination: string;
  travel_price: number;
  traveler: string;
  trip_id: number;
  trip_place: string;
  trip_purpose: string;
  trip_user: string;
  type: string;
  reserver_employee_number: number;
  hotel_type: string;
  used_at: string;
  country?: string;
  region?: string;
  prefecture?: string;
  hotel_price_limit?: number;
  approve_items: { [key: string]: string };
  grade_name?: string;
};

class TravelerCost {
  address: string;

  billAmount: number;

  canceledAt: string;

  chargingDepartmentCode: number;

  chargingDepartmentName: number;

  chargingDepartmentShareName: number;

  departmentCode: string;

  departmentName: string;

  employeeNumber: string;

  endAt: string;

  fee: number;

  hotelPriceLimitDiff: number;

  internalNumber: string;

  minusAmount: number;

  orderedAt: string;

  paymentType: string;

  privateUseDesc: string;

  projectCode: string;

  projectName: string;

  projectShareName: string;

  startAt: string;

  stayDays: number;

  summary: string;

  ticketDestination: string;

  travelPrice: number;

  traveler: string;

  tripId: number;

  tripPlace: string;

  tripPurpose: string;

  tripUser: string;

  type: string;

  reserverEmployeeNumber: number;

  hotelType: string;

  usedAt: string;

  country?: string;

  region?: string;

  prefecture?: string;

  hotelPriceLimit?: number;

  approveItems: { [key: string]: string };

  gradeName?: string;

  constructor(args: TravelerCostArgs) {
    this.address = args.address;
    this.billAmount = args.bill_amount;
    this.canceledAt = args.canceled_at;
    this.chargingDepartmentCode = args.charging_department_code;
    this.chargingDepartmentName = args.charging_department_name;
    this.chargingDepartmentShareName = args.charging_department_shareName;
    this.departmentCode = args.department_code;
    this.departmentName = args.department_name;
    this.employeeNumber = args.employee_number;
    this.endAt = args.end_at;
    this.fee = args.fee;
    this.hotelPriceLimitDiff = args.hotel_price_limit_diff;
    this.internalNumber = args.internal_number;
    this.minusAmount = args.minus_amount;
    this.orderedAt = args.ordered_at;
    this.paymentType = args.payment_type;
    this.privateUseDesc = args.private_use_desc;
    this.projectCode = args.project_code;
    this.projectName = args.project_name;
    this.projectShareName = args.project_share_name;
    this.startAt = args.start_at;
    this.stayDays = args.stay_days;
    this.summary = args.summary;
    this.ticketDestination = args.ticket_destination;
    this.travelPrice = args.travel_price;
    this.traveler = args.traveler;
    this.tripId = args.trip_id;
    this.tripPlace = args.trip_place;
    this.tripPurpose = args.trip_purpose;
    this.tripUser = args.trip_user;
    this.type = args.type;
    this.reserverEmployeeNumber = args.reserver_employee_number;
    this.hotelType = args.hotel_type;
    this.usedAt = args.used_at;
    this.country = args.country;
    this.region = args.region;
    this.prefecture = args.prefecture;
    this.hotelPriceLimit = args.hotel_price_limit;
    this.approveItems = args.approve_items;
    this.gradeName = args.grade_name;
  }
}

export default TravelerCost;
