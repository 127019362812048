import { formatPrice } from '@this/src/util';
import Expense from '@this/domain/expense/expense';

class ExpenseList {
  items: Expense[];

  constructor(items: Expense[] = [new Expense({})]) {
    this.items = items;
  }

  list() {
    return this.items.filter(item => !item.destroyed());
  }

  paidList() {
    return this.list().filter(item => item.paid);
  }

  unpaidList() {
    return this.list().filter(item => !item.paid);
  }

  addExpense() {
    this.items.push(new Expense({}));
    return app.render();
  }

  removeExpense(expense: Expense) {
    expense.markAsDestroy();
    return app.render();
  }

  selectedItems() {
    return this.items.filter(item => item.selected);
  }

  typeSummary() {
    return this.summarizeByType(this.list());
  }

  summarizeByType(items: Expense[]) {
    const summary = items.reduce((acc: { [key: string]: number }, expense) => {
      if (expense.type) {
        acc[expense.type] = (acc[expense.type] || 0) + expense.price;
      }
      acc['合計'] = (acc['合計'] || 0) + expense.price;
      return acc;
    }, {});

    return Object.fromEntries(Object.entries(summary).map(([key, value]) => [key, formatPrice(value)]));
  }

  errors() {
    return this.list().flatMap(expense => expense.errors());
  }

  totalPrice() {
    return this.list().reduce((sum, e) => sum + e.price, 0);
  }

  totalPaidPrice() {
    return this.paidList().reduce((sum, e) => sum + e.price, 0);
  }

  totalUnpaidPrice() {
    return this.unpaidList().reduce((sum, e) => sum + e.price, 0);
  }

  toCreateParams() {
    return this.items.map(expense => expense.toCreateParams());
  }

  toCreateExpenseApplicationParams() {
    return this.selectedItems().map(expense => ({ id: expense.id }));
  }
}

export default ExpenseList;
