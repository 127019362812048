import React, { useCallback, useState } from 'react';
import { styled } from '@this/src/components/constants/themes';
import type JalBookingInventoryItem from '@this/src/domain/arrangement/jal_booking_inventory_item';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import Notification from '@this/src/notification';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher } from '@this/src/util';
import { Box } from '@material-ui/core';
import JalBookingInventoryItemTableRow from './items_result_table_row';

interface Props {
  jalBookingInventoryItems: JalBookingInventoryItem[];
  totalCount: number;
  totalPage: number;
  page: number;
  fetchTodos: () => void;
  setPage: (page: number) => void;
  onCsvDownload: () => void;
}

const ItemsResultTable: React.FC<Props> = ({
  jalBookingInventoryItems,
  totalCount,
  totalPage,
  page,
  fetchTodos,
  setPage,
  onCsvDownload
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedOrderItemIds, setSelectedOrderItemIds] = useState<number[]>([]);

  const bulkTargetTodos = jalBookingInventoryItems.filter(item =>
    item.jalOrderItemId
      ? item.jalInventoriedBy === null
      : item.aiOperatorOrderItemId && item.aiOperatorInventoriedBy === null
  );

  const handleToggleAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        // 一括チェックは、jalOrderItemId を持つアイテムのみ対象
        setSelectedOrderItemIds(bulkTargetTodos.map(item => item.jalOrderItemId || 0).filter(Boolean));
      } else {
        setSelectedOrderItemIds([]);
      }
    },
    [bulkTargetTodos]
  );

  const handleToggle = useCallback((checked: boolean, orderItemId: number | null) => {
    if (orderItemId === null) return;
    setSelectedOrderItemIds(prevIds => {
      if (checked) {
        return prevIds.concat(orderItemId);
      }
      return prevIds.filter(id => id !== orderItemId);
    });
  }, []);

  const handleBulkInventory = useCallback(
    async (orderItemIds: number[]) => {
      if (loading) return;

      try {
        setLoading(true);
        const params = { order_item_ids: orderItemIds };
        await Fetcher.put('/arrangement/auto_reservation_inventory/jal_booking/items/bulk_inventory', params);
        fetchTodos();
        Notification.success('一括棚卸を実行しました');
      } catch {
        Notification.error('一括棚卸に失敗しました');
      } finally {
        setLoading(false);
      }
    },
    [loading, fetchTodos]
  );

  return (
    <>
      <Flex>
        <div>検索結果:{totalCount}件</div>
        <Button
          onClick={() => handleBulkInventory(selectedOrderItemIds)}
          disabled={totalCount === 0 || loading || selectedOrderItemIds.length === 0}
          loading={loading}
        >
          一括棚卸
        </Button>
        <Button onClick={onCsvDownload} disabled={totalCount === 0 || loading} loading={loading}>
          CSVダウンロード
        </Button>
      </Flex>
      <Box margin="10px 0">
        ※上下2段に分かれているカラムは、上段が予約指示（ai_operator_reservation）、下段が予約（jal_reservation）のもの
      </Box>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={
                  selectedOrderItemIds.length > 0 &&
                  bulkTargetTodos
                    .filter(item => item.jalOrderItemId) // jalOrderItemId を持つアイテムのみ対象
                    .every(item => selectedOrderItemIds.includes(item.jalOrderItemId!))
                }
                onChange={e => handleToggleAll(e.target.checked)}
              />
            </TableCell>
            <TableCell>ID</TableCell>
            <TableCell>作成日時</TableCell>
            <TableCell>予約指示ステータス</TableCell>
            <TableCell>予約番号</TableCell>
            <TableCell>搭乗日</TableCell>
            <TableCell>便名</TableCell>
            <TableCell>金額</TableCell>
            <TableCell>予約日</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>棚卸ステータス</TableCell>
            <TableCell>棚卸実施者</TableCell>
            <TableCell>棚卸日時</TableCell>
            <TableCell>Trip ID</TableCell>
            <TableCell>OrderItem ID</TableCell>
            <TableCell>Trace ID</TableCell>
            <TableCell>最新のログメッセージ</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jalBookingInventoryItems.map((item, i) => (
            <JalBookingInventoryItemTableRow
              key={item.aiOperatorReservationId || item.jalReservationId || i}
              item={item}
              checked={selectedOrderItemIds.includes(item.jalOrderItemId || item.aiOperatorOrderItemId || 0)}
              onInventory={() => fetchTodos()}
              onToggle={(checked: boolean) =>
                handleToggle(checked, item.jalOrderItemId || item.aiOperatorOrderItemId)
              }
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        count={totalPage}
        page={page}
        onChange={(_e: unknown, newPage: number) => setPage(newPage)}
      />
    </>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default ItemsResultTable;
