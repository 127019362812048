/* eslint-disable max-lines */
import React, { useCallback } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  RentalCarRadio,
  RentalCarRadioP
} from '@this/components/shared/search/search_block/search_block.template';
import { styled } from '@this/constants/themes';
import { toDomesticTel } from '@this/src/util';
import type { IndividualTargetSuppliedItem } from '@this/domain/individual_target_supplied_item';
import type { IndividualDomesticAirSuppliedItem } from '@this/domain/individual_domestic_air_supplied_item';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import type { CabinOption } from '@this/domain/cabin_option';
import { TransportElementAttachFileForm } from './transport_element_attach_file_form';
import type TransportElement from '../../../../../../domain/transport_element';
import type OrderItem from '../../../../../../domain/order_item';

import DatetimePicker from '../../../../../shared/datetime_picker/datetime_picker';

interface Props {
  element: TransportElement;
  index: number;
  orderItem: OrderItem;
  handleAirNumbersChange: (
    elementId: number,
    action: 'handleAirReservationNumberChange' | 'handleAirConfirmationNumberChange',
    e: string
  ) => void;
  classNameForModifiedField: (...path: (string | number)[]) => string;
  theme: { themeClass: string };
  serviceId: number;
  individualTargetSuppliedItems: IndividualTargetSuppliedItem[];
  suppliedItems: SuppliedItem[];
  individualDomesticAirSuppliedItems: IndividualDomesticAirSuppliedItem[];
  cabinOptions: CabinOption[];
}

const ticketTypeOption = (serviceId: number): { [s: string]: number | string }[] => {
  /* マイナビの場合は選択肢から楽天パッケージを除く  */
  const MYNAVI_SERVICE = 3;
  if (serviceId === MYNAVI_SERVICE) {
    return [
      { value: 0, label: '変更可' },
      { value: 1, label: '変更不可' },
      { value: 2, label: 'WBFパッケージ' }
    ];
  }
  return [
    { value: 0, label: '変更可' },
    { value: 1, label: '変更不可' },
    { value: 2, label: 'WBFパッケージ' },
    { value: 3, label: '楽天パッケージ' }
  ];
};

const ticketingTypeOptions = (serviceId: number) =>
  utils.isTabikobo(serviceId)
    ? [
        { value: 1, label: 'アリーズ' },
        { value: 3, label: 'IWAツアー' },
        { value: 4, label: '旅工房' }
      ]
    : [
        { value: 0, label: 'AIトラベル' },
        { value: 1, label: 'アリーズ' },
        { value: 5, label: 'フロンティア' },
        { value: 6, label: 'スカイツアーズ' }
      ];

const travelerShinkansenTicketType = (orderItem: OrderItem, i: number) => {
  if (orderItem.travelerInformations.length === 0) {
    return null;
  }

  switch (_.sortBy(orderItem.travelerInformations, ti => ti.id)[i].shinkansenTicketType) {
    case 'kyuusyuu':
      return 1;
    case 'bulk_ticket':
      return 2;
    case 'ex':
      return 3;
    default:
      return 0;
  }
};

const setShinkansenTicketTypeFromNumber = (orderItem: OrderItem, i: number, value: number) => {
  switch (value) {
    case 1:
      orderItem.travelerInformations[i].shinkansenTicketType = 'kyuusyuu';
      break;
    case 2:
      orderItem.travelerInformations[i].shinkansenTicketType = 'bulk_ticket';
      break;
    case 3:
      orderItem.travelerInformations[i].shinkansenTicketType = 'ex';
      break;
    default:
      orderItem.travelerInformations[i].shinkansenTicketType = 'normal';
      break;
  }
  app.render();
};

const travelerShinkansenSeatType = (orderItem: OrderItem, i: number) => {
  if (orderItem.travelerInformations.length === 0) {
    return null;
  }

  switch (orderItem.travelerInformations[i].shinkansenSeatType) {
    case 'reserve':
      return 1;
    case 'green':
      return 2;
    default:
      return 0;
  }
};

const setShinkansenSeatTypeFromNumber = (orderItem: OrderItem, i: number, value: number) => {
  switch (value) {
    case 1:
      orderItem.travelerInformations[i].shinkansenSeatType = 'reserve';
      break;
    case 2:
      orderItem.travelerInformations[i].shinkansenSeatType = 'green';
      break;
    default:
      orderItem.travelerInformations[i].shinkansenSeatType = 'non_reserved';
      break;
  }
  app.render();
};

const TransportElementForm = ({
  element,
  index,
  orderItem,
  handleAirNumbersChange,
  classNameForModifiedField: classNameForModifiedFieldRoot,
  theme,
  serviceId,
  cabinOptions,
  suppliedItems,
  individualDomesticAirSuppliedItems
}: Props) => {
  const classNameForModifiedField = useCallback(
    (...path: (string | number)[]) => classNameForModifiedFieldRoot('elements', index, ...path),
    [classNameForModifiedFieldRoot, index]
  );
  const classNameForModifiedFieldForTravelers = useCallback(
    (...path: (string | number)[]): string => {
      const modified = orderItem.travelerInformations.some(
        (_: unknown, i: number) => classNameForModifiedFieldRoot('travelerInformations', i, ...path) !== ''
      );
      return modified ? 'virtual-counte-price-change-form__modified_field' : '';
    },
    [classNameForModifiedFieldRoot, orderItem.travelerInformations.length]
  );

  const handleUploadFileChange = (
    file: File,
    transportElementId: number,
    travelerInformationId: string | null
  ) => {
    if (!file) return;
    convertFileToBase64(file)
      .then(base64String => {
        element.handleUploadFileData(
          {
            upload_file_name: file.name,
            content_type: file.type,
            traveler_information_id: travelerInformationId,
            upload_file_base64: base64String
          },
          transportElementId,
          travelerInformationId
        );
      })
      .catch(error => {
        console.error('Failed to convert file to Base64', error);
      });
  };

  const handleDeleteFile = (transportElementId: number, travelerInformationId: string | null, index: number) => {
    element.handleDeleteFile(transportElementId, travelerInformationId, index);
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64String = fileReader.result?.toString().replace(/^data:(.*);base64,/, '') || '';
        resolve(base64String);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  return (
    <>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('transportType')}`}>
          交通の種類
        </label>
        <select
          value={element.transportType}
          onChange={e => orderItem.handleTransportTypeChange(element, e.target.value)}
        >
          <option value="domestic_air">国内航空券</option>
          <option value="foreign_air">海外航空券</option>
          <option value="shinkansen">新幹線</option>
          <option value="express">特急</option>
          <option value="railway_ticket">在来線</option>
          <option value="rental_car">レンタカー</option>
          <option value="bus">バス</option>
          <option value="ana_tabisaku">ANA旅作</option>
          <option value="taxi">タクシー</option>
          <option value="foreign_railway">海外鉄道</option>
        </select>
      </div>
      {element.isForeignAir() ? (
        <div className="virtual-counte-price-change-form__input-wrapper">
          <label
            className={`virtual-counte-price-change-form__label ${classNameForModifiedField('airTicketType')}`}
          >
            座席クラス
          </label>
          <select value={element.cabin} onChange={e => element.handleCabinChange(e.target.value)}>
            {cabinOptions.map(d => (
              <option key={d.key} value={d.key}>
                {d.label}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      {element.isDomesticAir() ? (
        <>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField('airTicketType')}`}
            >
              券種
            </label>
            <select
              value={element.airTicketType}
              onChange={e => element.handleAirTicketTypeChange(e.target.value)}
            >
              {ticketTypeOption(serviceId).map(d => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </select>
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField('ticketingType')}`}
            >
              発券方法
            </label>
            <select
              value={element.ticketingType}
              onChange={e => {
                if (utils.isAiTravel(serviceId)) {
                  element.handleTicketingTypeChangeWithSuppliedId(
                    e.target.value,
                    orderItem,
                    suppliedItems,
                    individualDomesticAirSuppliedItems
                  );
                } else {
                  element.handleTicketingTypeChange(e.target.value);
                }
              }}
            >
              {ticketingTypeOptions(serviceId).map(d => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : null}
      {element.isShinkansen() || element.isExpress() || element.isForeignRailway() ? (
        <>
          {element.isSelectBulkTicket() && (
            <div className="virtual-counte-price-change-form__input-wrapper">
              <label
                className={`
                  virtual-counte-price-change-form__label
                  ${classNameForModifiedField('railwaySeatType')}
                  ${classNameForModifiedFieldForTravelers('shinkansenSeatType')}
                `}
              >
                座席
              </label>
              <div>
                {element.railwaySeatType ? (
                  <select
                    value={element.railwaySeatType || 0}
                    onChange={e => element.handleRailwaySeatTypeChange(e.target.value)}
                  >
                    <option value={0}>自由席</option>
                    <option value={1}>指定席</option>
                    <option value={2}>グリーン車</option>
                  </select>
                ) : (
                  orderItem.travelerInformations.map((ti: any, i: number) => (
                    <FlexRow key={i}>
                      <select
                        value={travelerShinkansenSeatType(orderItem, i) || 0}
                        onChange={e => setShinkansenSeatTypeFromNumber(orderItem, i, parseInt(e.target.value, 10))}
                      >
                        <option value={0}>自由席</option>
                        <option value={1}>指定席</option>
                        <option value={2}>グリーン車</option>
                      </select>
                      {(i > 0 || orderItem.travelerInformations.length > 1) && (
                        <span>
                          (
                          {ti.lastNameKana
                            ? `${ti.lastNameKana} ${ti.firstNameKana}`
                            : ti.email
                            ? ti.email
                            : ti.user.email}
                          )
                        </span>
                      )}
                    </FlexRow>
                  ))
                )}
              </div>
            </div>
          )}
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`
                virtual-counte-price-change-form__label
                ${classNameForModifiedField('railwayTicketType')}
                ${classNameForModifiedFieldForTravelers('shinkansenTicketType')}
              `}
            >
              券種
            </label>
            <div>
              {element.railwayTicketType ? (
                <select
                  value={element.railwayTicketType || 0}
                  onChange={e => element.handleRailwayTicketTypeChange(e.target.value)}
                >
                  <option value={0}>通常きっぷ</option>
                  <option value={1}>JR九州きっぷ</option>
                  <option value={2}>回数券</option>
                  <option value={3}>EX-IC</option>
                </select>
              ) : (
                orderItem.travelerInformations.map((ti: any, i: number) => (
                  <FlexRow key={i}>
                    <select
                      value={travelerShinkansenTicketType(orderItem, i) || 0}
                      onChange={e => setShinkansenTicketTypeFromNumber(orderItem, i, parseInt(e.target.value, 10))}
                    >
                      <option value={0}>通常きっぷ</option>
                      <option value={1}>JR九州きっぷ</option>
                      <option value={2}>回数券</option>
                      <option value={3}>EX-IC</option>
                    </select>
                    {(i > 0 || orderItem.travelerInformations.length > 1) && (
                      <span>
                        (
                        {ti.lastNameKana
                          ? `${ti.lastNameKana} ${ti.firstNameKana}`
                          : ti.email
                          ? ti.email
                          : ti.user.email}
                        )
                      </span>
                    )}
                  </FlexRow>
                ))
              )}
            </div>
          </div>
        </>
      ) : null}
      {element.transportType === 'rental_car' && element.carProviderOptions && (
        <div className="virtual-counte-price-change-form__input-wrapper">
          <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('carProvider')}`}>
            提供元
          </label>
          <select value={element.carProvider} onChange={e => element.handleCarProviderChange(e.target.value)}>
            {element.carProviderOptions.map((p, pi) => (
              <option key={`car_type_${pi}`} value={p[1]}>
                {p[0]}
              </option>
            ))}
          </select>
        </div>
      )}
      {element.transportType === 'taxi' && (
        <div className="virtual-counte-price-change-form__input-wrapper">
          <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('taxiService')}`}>
            サービス
          </label>
          <select value={element.taxiService} onChange={e => element.handleTaxiServiceChange(e.target.value)}>
            <option value="">選択なし</option>
            <option value="smartRyde">smartRyde</option>
          </select>
        </div>
      )}
      {element.isDomesticAir() ? (
        <>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`
                virtual-counte-price-change-form__label
                ${classNameForModifiedField('evoTicketType')}
                ${classNameForModifiedField('sakuraTicketType')}
              `}
            >
              券種詳細
            </label>
            <input
              type="text"
              value={element.ticketType()}
              onChange={e => element.handleTicketTypeChange(e.target.value)}
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`
                virtual-counte-price-change-form__label
                ${classNameForModifiedField('evoSeatType')}
                ${classNameForModifiedField('sakuraSeatType')}
              `}
            >
              座席クラス
            </label>
            <input
              type="text"
              value={element.seatType()}
              onChange={e => element.handleSeatTypeChange(e.target.value)}
            />
          </div>
        </>
      ) : null}
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className="virtual-counte-price-change-form__label">出発</label>
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('from', 'name')}`}>
          場所
        </label>
        <input
          type="text"
          value={element.from.name}
          onChange={e => element.from.handleChangeName(e.target.value)}
          placeholder="駅・空港名"
        />
      </div>
      {element.isRailway() ? (
        <div className="virtual-counte-price-change-form__input-wrapper-item">
          <label
            className={`virtual-counte-price-change-form__label ${classNameForModifiedField('boardingStation')}`}
          >
            乗車駅
            <span className="red">（必須）</span>
          </label>
          <input
            type="text"
            value={element.boardingStation}
            onChange={e => element.handleBoardingStationChange(e.target.value)}
          />
        </div>
      ) : null}
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('from', 'time')}`}>
          日時
        </label>
        <StyledDatetimePicker
          value={element.from.time}
          onChange={time => element.from.handleTimeChange(time)}
          disabledDays={0}
          showTime
          showPast
        />
      </div>
      {element.transportType === 'foreign_air' ? (
        <>
          <div className="virtual-counte-price-change-form__input-wrapper-item">
            <label className="virtual-counte-price-change-form__label">※表記は現地時間です。</label>
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper-item">
            {element.selectTimeZone.map((t, index) => {
              if (element.from.timeZone === t.value) {
                return (
                  <div
                    className={`
                      virtual-counte-price-change-form__timezone
                      ${classNameForModifiedField('from', 'timeZone')}
                    `}
                    key={t.value}
                    onClick={() => element.toggleFromTimeZoneList()}
                  >
                    <p className="virtual-counte-price-change-form__timezone-label">{t.label}</p>
                    <p className="virtual-counte-price-change-form__timezone-label">
                      {element.showFromTimeZoneList ? ' ▲' : ' ▼'}
                    </p>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper-item">
            {element.showFromTimeZoneList ? (
              <>
                <label className="virtual-counte-price-change-form__label" />
                <input
                  type="text"
                  name="fromTimeZone"
                  placeholder="Asia/Tokyoなど..."
                  onChange={e => element.handleSearchTimeZone(e)}
                />
              </>
            ) : null}
          </div>
          <div className="virtual-counte-price-change-form__input-timezone-list-wrapper">
            <ul>
              {element.filteredFromTimeZone.map((t, i) => (
                <li
                  key={i}
                  className="timezone-list__item"
                  onClick={() => element.handleFromTimeZoneChange(t.value)}
                >
                  <p>{t.label}</p>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
      {element.isAir() ? (
        <div className="virtual-counte-price-change-form__input-wrapper-item">
          <label className="virtual-counte-price-change-form__label">{element.calcFlightTime()}</label>
        </div>
      ) : null}
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className="virtual-counte-price-change-form__label">到着</label>
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('to', 'name')}`}>
          場所
        </label>
        <input
          type="text"
          value={element.to.name}
          onChange={e => element.to.handleChangeName(e.target.value)}
          placeholder="駅・空港名"
        />
      </div>
      {element.isRailway() ? (
        <div className="virtual-counte-price-change-form__input-wrapper-item">
          <label
            className={`virtual-counte-price-change-form__label ${classNameForModifiedField('arrivalStation')}`}
          >
            降車駅
            <span className="red">（必須）</span>
          </label>
          <input
            type="text"
            value={element.arrivalStation}
            onChange={e => element.handleArrivalStationChange(e.target.value)}
          />
        </div>
      ) : null}
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('to', 'time')}`}>
          日時
        </label>
        <StyledDatetimePicker
          value={element.to.time}
          onChange={time => element.to.handleTimeChange(time)}
          disabledDays={0}
          showTime
          showPast
        />
      </div>
      {element.transportType === 'foreign_air' ? (
        <>
          <div className="virtual-counte-price-change-form__input-wrapper-item">
            <label className="virtual-counte-price-change-form__label">※表記は現地時間です。</label>
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper-item">
            {element.selectTimeZone.map(t => {
              if (element.to.timeZone === t.value) {
                return (
                  <div
                    className={`
                      virtual-counte-price-change-form__timezone
                      ${classNameForModifiedField('to', 'timeZone')}
                    `}
                    key={t.value}
                    onClick={() => element.toggleToTimeZoneList()}
                  >
                    <p className="virtual-counte-price-change-form__timezone-label">{t.label}</p>
                    <p className="virtual-counte-price-change-form__timezone-label">
                      {element.showToTimeZoneList ? ' ▲' : ' ▼'}
                    </p>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            {element.showToTimeZoneList ? (
              <input
                type="text"
                name="toTimeZone"
                placeholder="Asia/Tokyoなど..."
                onChange={e => element.handleSearchTimeZone(e)}
              />
            ) : null}
          </div>
          <div className="virtual-counte-price-change-form__input-timezone-list-wrapper">
            <ul>
              {element.filteredToTimeZone.map((t, i) => (
                <li key={i} className="members-list__item" onClick={() => element.handleToTimeZoneChange(t.value)}>
                  <p>{t.label}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField('ticketNumber')}`}
            >
              航空券番号
            </label>
            <input
              type="text"
              value={element.ticketNumber}
              onChange={e => element.handleTicketNumberChange(e.target.value)}
            />
          </div>
        </>
      ) : null}
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('name')}`}>
          名前
          {element.isRailway() ? null : <span className="red">（必須）</span>}
        </label>
        <input
          type="text"
          value={element.name}
          onChange={e => element.handleNameChange(e.target.value)}
          placeholder="便名など"
        />
      </div>
      {theme.themeClass === 'mynavi' &&
      (element.transportType === 'domestic_air' ||
        element.transportType === 'foreign_air' ||
        element.transportType === 'shinkansen' ||
        element.transportType === 'express') ? (
        <div className="virtual-counte-price-change-form__input-wrapper">
          <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('ticketingAt')}`}>
            発券日
          </label>
          <StyledDatetimePicker
            value={element.ticketingAt}
            onChange={date => element.handleTicketingAtChange(date)}
            showPast
            disabledDays={0}
          />
        </div>
      ) : null}
      {element.transportType === 'domestic_air' ? (
        <>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField(
                'airReservationNumber'
              )}`}
            >
              予約番号
            </label>
            <input
              type="text"
              value={element.airReservationNumber}
              onChange={e =>
                handleAirNumbersChange(element.id, 'handleAirReservationNumberChange', e.target.value)
              }
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField(
                'airConfirmationNumber'
              )}`}
            >
              確認番号
            </label>
            <input
              type="text"
              value={element.airConfirmationNumber}
              onChange={e =>
                handleAirNumbersChange(element.id, 'handleAirConfirmationNumberChange', e.target.value)
              }
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField('ticketNumber')}`}
            >
              航空券番号
            </label>
            <input
              type="text"
              value={element.ticketNumber}
              onChange={e => element.handleTicketNumberChange(e.target.value)}
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <TransportElementAttachFileForm
              attachFiles={element.transportElementAttachFiles}
              travelerInformations={orderItem.travelerInformations}
              transportElement={element}
              onChange={(file, transportElementId, travelerInformationId) =>
                handleUploadFileChange(file, element.id, travelerInformationId)
              }
              onDelete={(transportElementId, travelerInformationId, index) =>
                handleDeleteFile(element.id, travelerInformationId, index)
              }
            />
          </div>
        </>
      ) : (
        <div className="virtual-counte-price-change-form__input-wrapper">
          <label
            className={`virtual-counte-price-change-form__label ${classNameForModifiedField('reservationNumber')}`}
          >
            予約番号
          </label>
          <input
            type="text"
            value={element.reservationNumber}
            onChange={e => element.handleReservationNumberChange(e.target.value)}
          />
        </div>
      )}
      {element.transportType === 'foreign_air' && (
        <div className="virtual-counte-price-change-form__input-wrapper">
          <TransportElementAttachFileForm
            attachFiles={element.transportElementAttachFiles}
            travelerInformations={orderItem.travelerInformations}
            transportElement={element}
            onChange={(file, transportElementId, travelerInformationId) =>
              handleUploadFileChange(file, element.id, travelerInformationId)
            }
            onDelete={(transportElementId, travelerInformationId, index) =>
              handleDeleteFile(element.id, travelerInformationId, index)
            }
          />
        </div>
      )}
      {(element.transportType === 'rental_car' || element.transportType === 'taxi') && (
        <>
          {element.carTypeOptions && (
            <>
              <div className="virtual-counte-price-change-form__input-wrapper">
                <label
                  className={`
                    virtual-counte-price-change-form__label
                    ${classNameForModifiedField('carType')}
                    ${classNameForModifiedField('carTypeOther')}
                  `}
                >
                  車種・クラス
                </label>
              </div>
              <div>
                {element.carTypeOptions.map((t, ti) => (
                  <RentalCarRadio key={`car_type_${ti}`}>
                    <input
                      type="radio"
                      value={t[1]}
                      onChange={() => element.handleCarTypeChange(t[1])}
                      checked={element.carType === t[1]}
                    />
                    <RentalCarRadioP>{t[0]}</RentalCarRadioP>
                  </RentalCarRadio>
                ))}
                <div>
                  <input
                    type="text"
                    value={element.carTypeOther}
                    placeholder="車種等"
                    onChange={e => element.handleCarTypeOtherChange(e.target.value)}
                    disabled={element.carType !== 'other'}
                  />
                </div>
              </div>
            </>
          )}
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`
                virtual-counte-price-change-form__label
                ${classNameForModifiedField('lastNameKana')}
                ${classNameForModifiedField('firstNameKana')}
              `}
            >
              {element.transportType === 'rental_car' ? '運転者氏名（カナ）' : '予約者氏名（カナ）'}
            </label>
            <input
              type="text"
              placeholder="例) ヤマダ"
              value={element.lastNameKana}
              onChange={e => element.handleLastNameKanaChange(e.target.value)}
            />
            <input
              type="text"
              placeholder="例) イチロウ"
              value={element.firstNameKana}
              onChange={e => element.handleFirstNameKanaChange(e.target.value)}
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('driverTel')}`}>
              {element.transportType === 'rental_car' ? '運転者電話番号' : '予約者電話番号'}
            </label>
            <input
              type="text"
              placeholder="例) 090-1111-2222"
              value={toDomesticTel(element.driverTel)}
              onChange={e => element.handleDriverTelChange(e.target.value)}
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('remarks')}`}>
              備考
            </label>
            <textarea value={element.remarks} onChange={e => element.handleRemarksChange(e.target.value)} />
          </div>
        </>
      )}
      {element.isRailway() && !element.isForeignRailway() ? (
        <>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField('postingNumber')}`}
            >
              特定記録番号
            </label>
            <input
              type="text"
              value={element.postingNumber}
              onChange={e => element.handlePostingNumberChange(e.target.value)}
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <label
              className={`virtual-counte-price-change-form__label ${classNameForModifiedField(
                'exicReceiptNumber'
              )}`}
            >
              EXICお預かり番号
            </label>
            <input
              type="text"
              value={element.exicReceiptNumber}
              onChange={e => element.handleExicReceiptNumberChange(e.target.value)}
            />
          </div>
          <div className="virtual-counte-price-change-form__input-wrapper">
            <TransportElementAttachFileForm
              attachFiles={element.transportElementAttachFiles}
              travelerInformations={orderItem.travelerInformations}
              transportElement={element}
              onChange={(file, transportElementId, travelerInformationId) =>
                handleUploadFileChange(file, element.id, travelerInformationId)
              }
              onDelete={(transportElementId, travelerInformationId, index) =>
                handleDeleteFile(element.id, travelerInformationId, index)
              }
            />
          </div>
        </>
      ) : null}
      {element.isForeignRailway() && (
        <div className="virtual-counte-price-change-form__input-wrapper">
          <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('remarks')}`}>
            備考
          </label>
          <textarea value={element.remarks} onChange={e => element.handleRemarksChange(e.target.value)} />
        </div>
      )}
    </>
  );
};

const StyledDatetimePicker = styled(DatetimePicker)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    padding: 2px !important;
    border: 2px solid ${({ theme }) => theme.accentColor}!important;
    font-size: 12px;
  }

  .rc-time-picker-input {
    height: 24px;
  }

  .rc-time-picker {
    margin-left: 4px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props => props.theme.grayTextColor};
`;

export default withTheme(TransportElementForm);
