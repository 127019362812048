import moment from 'moment-timezone';

export type SupportFeeElementJson = {
  id?: number;
  order_item_id?: number;
  reservedAt?: string;
  content?: string;
};

class SupportFeeElement {
  id?: number;

  orderItemId?: number;

  type: string;

  content: string;

  reservedAt: moment.Moment;

  constructor(args: SupportFeeElementJson = {}) {
    this.id = args.id;
    this.type = 'support_fee';
    this.content = args.content || '';
    this.reservedAt = moment(args.reservedAt);
  }

  handleContentChange(value: string) {
    this.content = value;
    return app.render();
  }

  handleReservedAtChange(value: moment.Moment) {
    this.reservedAt = value;
    return app.render();
  }

  startDate() {
    return null;
  }

  endDate() {
    return null;
  }

  description() {
    return `\
<出張サポート費>
■内容
${this.content}
■予約日
${this.reservedAt.format('MM/DD')}\
`;
  }

  title() {
    return '出張サポート費';
  }

  summary() {
    return `[出張サポート費]${this.content}`;
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  validationErrors() {
    const errors = [];
    if (this.content === '') {
      errors.push('出張サポート費の内容を入力してください。');
    }
    return errors;
  }

  updateParams() {
    return {
      type: this.type,
      content: this.content,
      reserved_at: this.reservedAt.format('YYYY-MM-DD')
    };
  }
}

export default SupportFeeElement;
