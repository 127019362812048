import moment from 'moment';

const { dig } = require('../../utils');

// Reusing types from the test file
type ActionType =
  | 'create'
  | 'update'
  | 'create_projects'
  | 'upsert_departments'
  | 'update_approvers'
  | 'update_project_approvers'
  | 'upsert_users'
  | 'delete_users'
  | 'upsert_account_types'
  | 'upsert_expenses_types'
  | 'upsert_admin_departments';

type Change = {
  before: any;
  after: any;
};

class BulkInvitationEmail {
  email: string;

  status: 'enqueued' | 'running' | 'success' | 'fail' | 'skip';

  changes?: { [key: string]: Change };

  actionType: ActionType;

  errorMessage: string;

  createdAt: string;

  updatedAt: string;

  constructor(raw: any, actionType: ActionType) {
    this.email = dig(raw, 'email');
    this.status = dig(raw, 'status');
    this.changes = raw.changes;
    this.actionType = actionType;
    this.errorMessage = raw.error_message;
    this.createdAt = moment(dig(raw, 'created_at')).format('YYYY-MM-DD HH:mm:ss');
    this.updatedAt = moment(dig(raw, 'updated_at')).format('YYYY-MM-DD HH:mm:ss');
  }

  statusMessage(): string {
    switch (this.status) {
      case 'enqueued':
        return '未着手';
      case 'running':
        return '着手中';
      case 'success':
        if (this.actionType === 'delete_users') {
          return '削除成功';
        }
        return `${this.changes ? '更新' : '作成'}成功`;
      case 'skip':
        return `スキップ: ${this.errorMessage}`;
      case 'fail':
        return `失敗: ${this.errorMessage}`;
      default:
        return '';
    }
  }

  isSuccess(): boolean {
    return this.status === 'success';
  }

  isFail(): boolean {
    return this.status === 'fail';
  }

  // TODO: 関数名から想起できるようにbool値を返すようにする
  hasChange(name: string): Change | null {
    if (!this.changes) {
      return null;
    }

    const change = this.changes[name];
    return change.before !== change.after ? change : null;
  }
}

export default BulkInvitationEmail;
