export type OrderItemPaymentArgs = {
  id?: number;
  orderItemId?: number;
  paymentType?: number;
};

class OrderItemPayment {
  id?: number;

  orderItemId?: number;

  paymentType: number;

  constructor(args: OrderItemPaymentArgs = {}) {
    this.id = args.id;
    this.orderItemId = args.orderItemId;
    this.paymentType = args.paymentType ?? 1;
  }

  handlePaymentTypeChange(value: string) {
    this.paymentType = parseInt(value, 10);
    return app.render();
  }

  paymentTypeString() {
    switch (this.paymentType) {
      case 0:
        return 'クレジットカード';
      case 1:
        return '請求書';
      case 2:
        return '現地払い';
      case 3:
        return '回数券';
      default:
        return '';
    }
  }

  isBulkTicket() {
    return this.paymentType === 3;
  }

  updateParams() {
    return { payment_type: this.paymentType };
  }
}

export default OrderItemPayment;
