import React, { useEffect, useState } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { Fetcher } from '@this/src/util';

type Props = {
  trip: Trip;
};

interface AiOperatorResponse {
  result: Array<{
    id: number;
    reservation_type: 'jal';
    trace_id: number;
    transport_element_id: number;
    status:
      | 'reservation_queue'
      | 'reserving'
      | 'reserved'
      | 'ticket_queue'
      | 'ticketing'
      | 'ticketed'
      | 'full'
      | 'failed';
    input_data: string; // JSON文字列
    air_reservation_number: string | null;
    air_confirmation_number: string | null;
    started_at: string;
    finished_at: string | null;
    created_at: string;
    updated_at: string;
    failed_at: string | null;
    failure_reason: string | null;
    service_id: number;
  }>;
}

export const AioperatorInfo = ({ trip }: Props) => {
  const [aiOperatorData, setAiOperatorData] = useState<AiOperatorResponse | null>(null);

  useEffect(() => {
    Fetcher.get<AiOperatorResponse>(
      `/arrangement/ai_operator_reservations/show_by_trip_id?trip_id=${trip.id}`
    ).then(res => {
      setAiOperatorData(res);
    });
  }, []);

  return (
    <Root className="aioperator">
      <h3>AI Operator予約</h3>
      {aiOperatorData?.result.map(res => (
        <div key={res.id}>
          <p>ステータス: {res.status}</p>
          <p>予約データ:</p>
          <p style={{ fontSize: '10px' }}>{res.input_data}</p>
        </div>
      ))}
    </Root>
  );
};
const Root = styled.div``;
