import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { rgba } from 'polished';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type JalBookingInventoryItem from '@this/src/domain/arrangement/jal_booking_inventory_item';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/components/shared/ui/inputs/button';
import { OrderItemStatusForm } from '@this/components/arrangement/shared/price_change_form';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher } from '@this/src/util';
import LogModal from '@this/src/components/arrangement/todo_list/log_modal';

interface Props {
  item: JalBookingInventoryItem;
  checked: boolean;
  onInventory: () => void;
  onToggle: (checked: boolean) => void;
}

const ItemsResultTableRow = observer(({ item, checked, onInventory, onToggle }: Props) => {
  const [showLogModal, setShowLogModal] = useState<boolean>(false);

  const handleInventory = async () => {
    const targetOrderItemId = item.jalOrderItemId || item.aiOperatorOrderItemId;
    if (!targetOrderItemId) return;
    await Fetcher.put(
      `/arrangement/auto_reservation_inventory/jal_booking/items/${targetOrderItemId}/inventory.json`,
      {}
    );
    onInventory();
  };

  const handleCancelInventory = async () => {
    const targetOrderItemId = item.jalOrderItemId || item.aiOperatorOrderItemId;
    if (!targetOrderItemId) return;
    await Fetcher.put(
      `/arrangement/auto_reservation_inventory/jal_booking/items/${targetOrderItemId}/cancel_inventory.json`,
      {}
    );
    onInventory();
  };

  return (
    <TableRow style={{ background: !item.jalOrderItemId ? rgba('red', 0.3) : 'auto' }}>
      <TableCell>
        {/* 一括棚卸用チェック */}
        <Checkbox
          checked={checked}
          disabled={
            item.jalOrderItemId
              ? Boolean(item.jalInventoriedAt)
              : Boolean(item.aiOperatorOrderItemId && item.aiOperatorInventoriedAt)
          }
          onChange={e => onToggle(e.target.checked)}
        />
      </TableCell>
      <TableCell>
        {/* ID */}
        {item.aiOperatorReservationId || '　'}
        <br />
        {item.jalReservationId || '　'}
      </TableCell>
      <TableCell>
        {/* 作成日時 */}
        {item.aiOperatorReservationCreatedAt?.format('YYYY-MM-DD HH:mm') || '　'}
        <br />
        {item.jalReservationCreatedAt?.format('YYYY-MM-DD HH:mm') || '　'}
      </TableCell>
      <TableCell>
        {/* 予約指示ステータス */}
        {item.aiOperatorReservationStatus}
      </TableCell>
      <TableCell>
        {/* 予約番号 */}
        {item.reservationNumber}
      </TableCell>
      <TableCell>
        {/* 予約日 */}
        {item.targetDate?.format('YYYY-MM-DD')}
      </TableCell>
      <TableCell>
        {/* 便名 */}
        {item.flightName}
      </TableCell>
      <TableCell>
        {/* 予約金額 */}
        {item.price?.toLocaleString()}
      </TableCell>
      <TableCell>
        {/* 予約日 */}
        {item.reservationDate?.format('YYYY-MM-DD')}
      </TableCell>
      <TableCell>
        {/* 予約ステータス */}
        {item.aiOperatorOrderItemId && item.aiOperatorJobType && item.aiOperatorStatus && (
          <OrderItemStatusForm
            orderItemId={item.aiOperatorOrderItemId}
            jobType={item.aiOperatorJobType}
            status={item.aiOperatorStatus}
            onJobTypeChange={value => {
              item.aiOperatorJobType = value;
            }}
            onStatusChange={value => {
              item.aiOperatorStatus = value;
            }}
            showLabel={false}
            fetchData={handleInventory}
          />
        )}
        {item.jalOrderItemId && item.jalJobType && item.jalStatus && (
          <OrderItemStatusForm
            orderItemId={item.jalOrderItemId}
            jobType={item.jalJobType}
            status={item.jalStatus}
            onJobTypeChange={value => {
              item.jalJobType = value;
            }}
            onStatusChange={value => {
              item.jalStatus = value;
            }}
            showLabel={false}
            fetchData={handleInventory}
          />
        )}
      </TableCell>
      <TableCell>
        {/* 棚卸済 */}
        {item.aiOperatorIsInventoried || '　'}
        <br />
        {item.jalIsInventoried || '　'}
      </TableCell>
      <TableCell>
        {/* 棚卸者 */}
        {item.aiOperatorInventoriedBy || '　'}
        <br />
        {item.jalInventoriedBy || '　'}
      </TableCell>
      <TableCell>
        {/* 棚卸日時 */}
        {item.aiOperatorInventoriedAt?.format('YYYY-MM-DD HH:mm') || '　'}
        <br />
        {item.jalInventoriedAt?.format('YYYY-MM-DD HH:mm') || '　'}
      </TableCell>
      <TableCell>
        {/* Trip ID */}
        <Link
          href={`/arrangement/virtual_counter?trip_id=${item.aiOperatorTripId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.aiOperatorTripId || '　'}
        </Link>
        <br />
        <Link
          href={`/arrangement/virtual_counter?trip_id=${item.jalTripId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.jalTripId || '　'}
        </Link>
      </TableCell>
      <TableCell>
        {/* OrderItem ID */}
        {item.aiOperatorOrderItemId || '　'}
        <br />
        {item.jalOrderItemId || '　'}
      </TableCell>
      <TableCell>
        {/* Trace ID */}
        {item.aiOperatorTraceId || '　'}
        <br />
        {item.jalTraceId || '　'}
      </TableCell>
      <TableCell>
        {/* 最終ログメッセージ */}
        {item.lastLogMessage && <span>{item.lastLogMessage}</span>}
        {item.lastLogMessageArranger && <span>({item.lastLogMessageArranger})</span>}
        {(item.jalOrderItemId || item.aiOperatorOrderItemId) && (
          <p>
            (<Link onClick={() => setShowLogModal(true)}>ログ</Link>)
          </p>
        )}
      </TableCell>
      <TableCell>
        {/* 棚卸操作 */}
        {(
          item.jalOrderItemId ? item.jalInventoriedAt : item.aiOperatorOrderItemId && item.aiOperatorInventoriedAt
        ) ? (
          <Button size="small" onClick={() => handleCancelInventory()}>
            取消
          </Button>
        ) : (
          <Button size="small" onClick={() => handleInventory()}>
            棚卸完了
          </Button>
        )}
      </TableCell>
      {showLogModal && (item.jalTraceId || item.aiOperatorTraceId) && (
        <LogModal traceId={(item.jalTraceId ?? item.aiOperatorTraceId)!} onClose={() => setShowLogModal(false)} />
      )}
    </TableRow>
  );
});

export default ItemsResultTableRow;
