/* eslint-disable no-underscore-dangle */
import moment from 'moment';

export type ExpenseArgs = {
  id?: number;
  date?: string;
  type?: string;
  price?: number;
  abstract?: string;
  projectId?: number;
  memo?: string;
  internalNumber?: string;
  paid?: boolean;
};

class Expense {
  id?: number;

  date: moment.Moment;

  type: string;

  price: number;

  abstract: string;

  projectId?: number;

  memo?: string;

  internalNumber: string;

  paid?: boolean;

  selected: boolean;

  _destroy: number;

  constructor(args: ExpenseArgs = {}) {
    this.id = args.id;
    this.date = moment(args.date) || moment();
    this.type = args.type || '';
    this.price = args.price || 0;
    this.abstract = args.abstract || '';
    this.projectId = args.projectId;
    this.memo = args.memo;
    this.internalNumber = args.internalNumber || '';
    this.paid = args.paid;
    this.selected = true;
    this._destroy = 0;
  }

  setDate(value: moment.Moment) {
    this.date = value;
    return app.render();
  }

  setType(value: string) {
    this.type = value;
    return app.render();
  }

  setPrice(value: string) {
    this.price = parseInt(value, 10);
    return app.render();
  }

  setProjectId(value: string) {
    this.projectId = parseInt(value, 10);
    return app.render();
  }

  setMemo(value: string) {
    this.memo = value;
    return app.render();
  }

  setAbstract(value: string) {
    this.abstract = value;
    return app.render();
  }

  setInternalNumber(value: string) {
    this.internalNumber = value;
    return app.render();
  }

  toggleSelected() {
    this.selected = !this.selected;
    return app.render();
  }

  markAsDestroy() {
    this._destroy = 1;
  }

  destroyed() {
    return this._destroy === 1;
  }

  errors() {
    const errors = [];
    if (!this.date.isValid()) {
      errors.push('経費の日付を正しく入力してください');
    }
    if (!this.type) {
      errors.push('経費の種別を選択してください');
    }
    return errors;
  }

  toCreateParams() {
    return {
      id: this.id,
      date: this.date.format('YYYY-MM-DD'),
      type: this.type,
      price: this.price,
      project_id: this.projectId,
      memo: this.memo,
      abstract: this.abstract,
      internal_number: this.internalNumber,
      _destroy: this.destroyed() ? this._destroy : undefined
    };
  }
}

export default Expense;
