export type OrganizationAddressArgs = {
  id?: number;
  postal_code?: string;
  location?: string;
  addressee?: string;
};

export default class OrganizationAddress {
  id: number | null;

  postalCode: string;

  location: string;

  addressee: string;

  constructor(args: OrganizationAddressArgs) {
    this.id = args?.id || null;
    this.postalCode = args?.postal_code || '';
    this.location = args?.location || '';
    this.addressee = args?.addressee || '';
  }
}
