/**
 * 電話番号の全角文字を半角文字に変換するユーティリティ関数
 */

/**
 * 半角ハイフンに変換する全角ハイフン系記号の配列
 */
const FULLWIDTH_HYPHEN_CHARS = [
  '－', // 全角ハイフンマイナス
  '‐', // 全角ハイフン
  '―', // 全角ダッシュ
  'ー', // 長音記号
  '−', // 数学的マイナス記号
  '‒', // フィギュアダッシュ
  '–', // エヌダッシュ
  '—', // エムダッシュ
  '━' // 全角マイナスハイフン
];

/**
 * 全角数字と全角ハイフン系記号を半角に変換する
 * @param value 変換対象の文字列
 * @returns 変換後の文字列
 */
export function convertFullWidthToHalfWidth(value: string): string {
  if (!value) return value;

  return value.replace(/[０-９－‐―ー−‒–—━]/g, s => {
    // 全角ハイフン系記号をすべて半角ハイフンに変換
    if (FULLWIDTH_HYPHEN_CHARS.includes(s)) return '-';
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
}
