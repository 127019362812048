import type React from 'react';
import type ReserveInfo from '@this/domain/reserve_info';
import { convertFullWidthToHalfWidth } from '@this/domain/tel_number_utils';
import type { OrderItemMappingMode } from '@this/domain/order_item_mapping';
import type { Props as ComponentProps } from './input_customer_information';

type State = {
  validationErrors: { [key: string]: string | undefined };
  isRentalCar: boolean;
  showDepartmentDetail: boolean;
  departmentDetailMode: OrderItemMappingMode;
  showProjectDetail: boolean;
  projectDetailMode: OrderItemMappingMode;
  showExpensesAccountTypeDetail: boolean;
  expensesAccountTypeDetailMode: OrderItemMappingMode;
  isSubmitting: boolean;
  exicPasswordTypes: string[];
  showAlert: boolean;
};

/**
 * 電話番号関連のハンドラーを提供するファイル
 */

/**
 * 緊急連絡先電話番号のフォーカスが外れた時のバリデーション処理を行うハンドラーを作成
 * @param component コンポーネントのインスタンス
 * @param reserveInfo 予約情報
 * @returns ハンドラー関数
 */
export function createTelNumberHandlers(component: {
  setState: <K extends keyof State>(
    state:
      | State
      | ((prevState: Readonly<State>, props: Readonly<ComponentProps>) => State | Pick<State, K> | null)
      | Pick<State, K>
      | null,
    callback?: () => void
  ) => void;
  props: ComponentProps;
}) {
  /**
   * 電話番号のバリデーションを行う
   * @param i
   * @param value 電話番号
   * @returns エラーメッセージまたはundefined
   */
  const validateTelNumber = (i: number, value: string) => {
    return (component.props.reserveInfo.constructor as typeof ReserveInfo).telNumberError(
      '搭乗者の緊急連絡先',
      value
    );
  };

  /**
   * 緊急連絡先電話番号のフォーカスが外れた時のバリデーション
   * @param i
   * @returns イベントハンドラー
   */
  const handleTelNumberBlur = (i: number) => (e: React.FocusEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // 全角数字と全角ハイフンを半角に変換
    const convertedValue = convertFullWidthToHalfWidth(value);

    if (value !== convertedValue) {
      value = convertedValue;
      component.props.reserveInfo.travelers.list[i].flightTel = value;
    }

    const error = validateTelNumber(i, value);

    if (error) {
      component.setState((prevState: State) => ({
        validationErrors: {
          ...prevState.validationErrors,
          [`flightCustomerTel_${i}`]: error
        }
      }));
    } else {
      component.setState((prevState: State) => {
        const newErrors = { ...prevState.validationErrors };
        delete newErrors[`flightCustomerTel_${i}`];
        return { validationErrors: newErrors };
      });
    }
  };

  return {
    handleTelNumberBlur,
    validateTelNumber
  };
}
